import * as React from "react";
import { useState, useCallback } from 'react';
import { StyleSheet, View, Pressable, Text, ScrollView, ActivityIndicator, RefreshControl } from "react-native";
import { Image } from "expo-image";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase, useRoute, useFocusEffect } from "@react-navigation/native";
import { Color, Padding, Border, FontSize, FontFamily } from "../GlobalStyles";
import { useOrg } from '../lib/useOrg';
import { supabase } from '../lib/supabase';
import TopBar from "../components/TopBar";
import FilterBar from "../components/FilterBar";
import { useEffect } from "react";

const PeriodTickets = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const { currentOrganization } = useOrg();
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [sortField, setSortField] = useState('function_time');
  const [sortAscending, setSortAscending] = useState(false);
  const [locations, setLocations] = useState([]);
  const [devices, setDevices] = useState([]);
  const route = useRoute();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const handleParams = async () => {
      if (route.params?.deviceid) {
        const { data } = await supabase
          .from('devices')
          .select('*')
          .eq('deviceid', route.params.deviceid)
          .single();
        if (data) setSelectedDevice(data);
      } else if (route.params?.locationid) {
        const { data } = await supabase
          .from('locations')
          .select('*')
          .eq('locationid', route.params.locationid)
          .single();
        if (data) setSelectedLocation(data);
      }
    };
    
    handleParams();
  }, [route.params]);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortAscending(!sortAscending);
    } else {
      setSortField(field);
      setSortAscending(true);
    }
  };
  
  const clearFilters = () => {
    setSelectedLocation(null);
    setSelectedDevice(null);
    setSortField('function_time');
    setSortAscending(false);
    setStartDate(null);
    setEndDate(null);
  };

  const fetchTickets = async () => {
    if (!currentOrganization?.orgid) {
      setError("Waiting for organization ID...");
      return;
    }

    setLoading(true);
    setRefreshing(true);
    
    try {
      // 1. First get all devices for the organization
      const { data: deviceData, error: devicesError } = await supabase
        .from('devices')
        .select('deviceid, devicename, devicedesc, locationid')
        .eq('orgid', currentOrganization.orgid);

      if (devicesError) throw devicesError;
      setDevices(deviceData);

      const deviceIds = deviceData.map(d => d.deviceid);

      // 2. Get tickets for these devices
      let ticketQuery = supabase
        .from('v2_analyticsaccountingtickets')
        .select('*');

      if (selectedDevice) {
        ticketQuery = ticketQuery.eq('deviceid', selectedDevice.deviceid);
      } else if (selectedLocation) {
        const locationDevices = deviceData
          .filter(d => d.locationid === selectedLocation.locationid)
          .map(d => d.deviceid);
        ticketQuery = ticketQuery.in('deviceid', locationDevices);
      }

      if (startDate) {
        ticketQuery = ticketQuery.gte('function_time', Math.floor(startDate.getTime() / 1000));
      }
      if (endDate) {
        const nextDay = new Date(endDate);
        nextDay.setDate(nextDay.getDate() + 1);
        ticketQuery = ticketQuery.lt('function_time', Math.floor(nextDay.getTime() / 1000));
      }

      ticketQuery = ticketQuery.order(sortField, { ascending: sortAscending });

      const { data: ticketData, error: ticketError } = await ticketQuery;
      if (ticketError) throw ticketError;

      // Get unique location IDs from the devices
      const locationIds = [...new Set(deviceData
        .map(device => device.locationid)
        .filter(id => id))];

      // 3. Get location information
      const { data: locationData, error: locationError } = await supabase
        .from('locations')
        .select('locationid, locationname')
        .in('locationid', locationIds);

      if (locationError) throw locationError;

      // Create a location lookup map
      const locationMap = locationData.reduce((acc, loc) => {
        acc[loc.locationid] = loc.locationname;
        return acc;
      }, {});

      // Combine all data
      const enrichedTickets = ticketData.map(ticket => {
        const deviceInfo = deviceData.find(d => d.deviceid === ticket.deviceid) || {};
        return {
          ...ticket,
          deviceName: deviceInfo.devicename || 'Unknown Device',
          deviceDesc: deviceInfo.devicedesc || '',
          locationName: locationMap[deviceInfo.locationid] || 'Unknown Location'
        };
      });

      setTickets(enrichedTickets);
      setLocations(locationData);
      setError('');
    } catch (e) {
      console.error(e);
      setError("Error fetching data, will retry...");
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  useFocusEffect(
    useCallback(() => {
      fetchTickets();
    }, [currentOrganization?.orgid, selectedLocation, selectedDevice, sortField, sortAscending, startDate, endDate])
  );

  const decodeHexToAscii = (hex) => {
    try {
      // Convert hex to string
      const hexString = hex.toString();
      let str = '';
      for (let i = 0; i < hexString.length; i += 2) {
        const chunk = hexString.substr(i, 2);
        str += String.fromCharCode(parseInt(chunk, 16));
      }
      
      // Clean up the string
      return str
        .replace(/\x1b[@-_]/g, '') // Remove escape sequences
        .replace(/[^\x20-\x7E\n]/g, '') // Keep only printable ASCII and newlines
        .split('\n') // Split into lines
        .map(line => line.trim()) // Trim each line
        .filter(line => line) // Remove empty lines
        .join('\n'); // Rejoin with newlines
    } catch (e) {
      console.error('Error decoding hex:', e);
      return 'Error decoding ticket data';
    }
  };

  const formatCurrency = (amount) => {
    if (!amount) return '$0.00';
    return `$${parseFloat(amount).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;
  };

  const formatDateTime = (date, time) => {
    return `${date || ''} ${time || ''}`.trim() || 'N/A';
  };

  if (loading) {
    return (
      <View style={styles.centered}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  if (error) {
    return (
      <View style={styles.centered}>
        <Text>{error}</Text>
      </View>
    );
  }

  return (
    <View style={styles.deviceDetail}>

      <TopBar 
        title={selectedDevice?.devicename || selectedLocation?.locationname || "Period Tickets"}
        navigation={navigation}
        showBack={!!(selectedDevice || selectedLocation)}
      />

      <FilterBar
        locations={locations}
        devices={devices}
        selectedLocation={selectedLocation}
        selectedDevice={selectedDevice}
        sortField={sortField}
        sortAscending={sortAscending}
        onLocationChange={setSelectedLocation}
        onDeviceChange={setSelectedDevice}
        onSortChange={handleSort}
        onClearFilters={clearFilters}
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
      />
      
      <ScrollView
        style={styles.scrollable}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={fetchTickets} />
        }
      >
        {tickets.map((ticket) => (
          <Pressable
          key={ticket.eventid}
          style={styles.ticketCard}
          onPress={() => navigation.navigate("PeriodTicketDetail", { eventid: ticket.eventid })}
          >
            <View style={styles.cardContent}>
              {/* Left side - Ticket info */}
              <View style={styles.ticketInfo}>
                <View style={styles.deviceInfo}>
                  <Text style={styles.functionTime}>
                    {new Date(ticket.function_time * 1000).toLocaleString('en-US', {
                      year: 'numeric',
                      month: 'long', 
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true 
                    })}
                  </Text>
                  <Text style={styles.deviceName}>{ticket.deviceName}</Text>
                  <Text style={styles.locationName}>{ticket.locationName}</Text>
                </View>
                
                <View style={styles.ticketDetails}>
                  <View style={styles.timeInfo}>
                    <Text style={styles.label}>Period: {ticket.ticket_period || 'N/A'}</Text>
                    <Text style={styles.label}>
                      From: {formatDateTime(ticket.from_date, ticket.from_time)}
                    </Text>
                    <Text style={styles.label}>
                      To: {formatDateTime(ticket.to_date, ticket.to_time)}
                    </Text>
                  </View>
                  
                  <View style={styles.amounts}>
                    <View style={styles.amountRow}>
                      <View style={styles.circleIcon}>
                        <Image
                          style={styles.icon}
                          contentFit="cover"
                          source={require("../assets/pluscircle2.png")}
                        />
                      </View>
                      <Text style={styles.amount}>In: {formatCurrency(ticket.total_in)}</Text>
                    </View>
                    <View style={styles.amountRow}>
                      <View style={styles.circleIcon}>
                        <Image
                          style={styles.icon}
                          contentFit="cover"
                          source={require("../assets/minuscircle2.png")}
                        />
                      </View>
                      <Text style={styles.amount}>Out: {formatCurrency(ticket.total_out)}</Text>
                    </View>
                  </View>
                  
                  <Text style={styles.functionTime}>
                    Function Time: {ticket.function_time || 'N/A'}
                  </Text>
                </View>
              </View>

              {/* Right side - Decoded hex ticket */}
              <View style={styles.ticketHex}>
                <ScrollView style={styles.hexScroll}>
                  <Text style={styles.hexText}>
                    {ticket.ticket_hex ? decodeHexToAscii(ticket.ticket_hex) : 'No ticket data'}
                  </Text>
                </ScrollView>
              </View>
            </View>
          </Pressable>
        ))}
        <View style={{ height: 40 }} />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  hamburgerIcon: {
    height: 15,
    width: 20,
  },
  organizationName: {
    fontWeight: "600",
    fontFamily: FontFamily.interSemiBold,
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  ticketCard: {
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    padding: Padding.p_base,
    marginVertical: 6,
    marginHorizontal: 12,
  },
  
  cardContent: {
    flexDirection: 'row',
    flex: 1,
  },
  
  ticketInfo: {
    flex: 2,
    paddingRight: 10,
    borderRightWidth: 1,
    borderRightColor: Color.colorGainsboro_100,
  },
  
  ticketHex: {
    flex: 1,
    marginLeft: 10,
    maxHeight: 240,
  },
  
  hexScroll: {
    flex: 1,
  },
  
  hexText: {
    fontFamily: 'Courier',
    fontSize: FontSize.size_xs,
    color: Color.labelColorLightSecondary,
    lineHeight: 16,
  },
  
  circleIcon: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: Color.systemBackgroundLightPrimary,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.00,
    elevation: 1,
  },
  
  icon: {
    width: 18,
    height: 18,
  },
  
  amountRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 6,
  },
  
  amount: {
    fontSize: FontSize.size_smi,
    fontFamily: FontFamily.interBold,
    color: Color.labelColorLightSecondary,
    flex: 1,
  },
  
  deviceInfo: {
    marginBottom: 10,
  },
  
  deviceName: {
    fontSize: FontSize.size_mini,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    color: Color.labelColorLightPrimary,
  },
  
  locationName: {
    fontSize: FontSize.size_smi,
    color: Color.labelColorLightSecondary,
    marginTop: 2,
  },
  
  ticketDetails: {
    marginTop: 8,
  },
  
  timeInfo: {
    marginBottom: 10,
  },
  
  label: {
    fontSize: FontSize.size_smi,
    color: Color.labelColorLightSecondary,
    marginVertical: 2,
  },
  
  amounts: {
    marginVertical: 8,
  },
  
  functionTime: {
    fontSize: FontSize.size_smi,
    color: Color.labelColorLightSecondary,
    marginTop: 8,
  },
  
  backButton: {
    width: 60,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    backgroundColor: 'transparent',
  },
  
  backButtonImage: {
    width: '60%',
    height: '60%',
    resizeMode: 'contain',
  },
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollableScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  moneyinframe: {
    marginLeft: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  locframe: {
    marginLeft: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  text4Typo: {
    fontSize: FontSize.size_smi,
    color: Color.labelColorLightSecondary,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    lineHeight: 18,
    textAlign: "left",
    letterSpacing: 0,
  },
  locationNameLayout: {
    lineHeight: 20,
    letterSpacing: 0,
  },
  devicecardcontentLayout: {
    width: 288,
    marginLeft: 10,
  },
  devicecardcontent: {
    height: 41,
    marginLeft: 10,
  },
  dashCardBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    flexDirection: "row",
  },
  locationsFlexBox: {
    marginTop: 11,
    alignSelf: "stretch",
    alignItems: "center",
  },
  topbarPosition: {
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  locationcardBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
  },
  textTypo: {
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontSize: FontSize.size_smi,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    letterSpacing: 0,
  },
  letterFlexBox: {
    textAlign: "center",
    color: Color.colorGray_100,
    zIndex: 1,
    lineHeight: 18,
    display: "flex",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
    justifyContent: "center",
    position: "absolute",
    alignItems: "center",
  },
  devicedetailscardSpaceBlock: {
    marginTop: 11,
    alignSelf: "stretch",
  },
  iconLayout: {
    height: 20,
    zIndex: 0,
    width: 20,
  },
  leticon2Position: {
    right: 14,
    top: 14,
    position: "absolute",
  },
  detailTypo: {
    fontFamily: FontFamily.interRegular,
    fontSize: FontSize.size_base,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    textAlign: "left",
    letterSpacing: 0,
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  hamburger: {
    height: 15,
    width: 20,
  },
  toptext: {
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  sliderhorizontal3Icon: {
    width: 21,
    height: 18,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    overflow: "hidden",
    alignItems: "center",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  locationimgIcon: {
    borderRadius: Border.br_12xs,
    width: 42,
    height: 42,
  },
  locationtitle: {
    fontSize: FontSize.size_mini,
    lineHeight: 20,
    width: 262,
    display: "flex",
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    letterSpacing: 0,
    alignItems: "center",
  },
  text: {
    display: "none",
    width: 92,
    alignItems: "center",
  },
  lanyardcardIcon: {
    width: 11,
    height: 15,
  },
  nodevicesval: {
    marginLeft: 1,
  },
  nodevicesframe: {
    marginLeft: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  pluscircleIcon: {
    height: 20,
    width: 20,
  },
  details2: {
    flexDirection: "row",
    alignItems: "center",
  },
  letter: {
    top: 2,
    left: 0,
    fontSize: FontSize.size_2xs,
    height: 12,
    zIndex: 1,
    width: 15,
  },
  leticon: {
    flexDirection: "row",
    alignItems: "center",
  },
  moneyoutframe1: {
    marginLeft: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  details3: {
    marginTop: 1,
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  details1: {
    width: 288,
  },
  content: {
    marginLeft: 10,
    width: 288,
  },
  locationcard: {
    backgroundColor: Color.systemBackgroundLightPrimary,
    padding: Padding.p_base,
    alignSelf: "stretch",
  },
  pluscircleIcon1: {
    width: 15,
    height: 15,
  },
  today1: {
    fontSize: FontSize.size_base,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    letterSpacing: 0,
  },
  text1: {
    marginTop: 2,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  total: {
    marginLeft: 15,
  },
  details4: {
    flexDirection: "row",
  },
  graph: {
    backgroundColor: Color.colorGainsboro_200,
    height: 70,
    marginTop: 10,
    alignSelf: "stretch",
  },
  content1: {
    zIndex: 1,
    marginLeft: 10,
    flex: 1,
  },
  dashWinsCard: {
    flexDirection: "row",
    flex: 1,
  },
  revenuecardswinsbets: {
    flexDirection: "row",
    alignItems: "center",
  },
  letter2: {
    top: 1,
    right: 0,
    fontSize: FontSize.size_sm,
    height: 17,
    zIndex: 1,
    width: 20,
  },
  leticon2: {
    zIndex: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  bodyText: {
    marginTop: 5,
  },
  detailitemgroup: {
    marginTop: 20,
    justifyContent: "center",
  },
  detail: {
    marginTop: 20,
  },
  devicedetailscard: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
    justifyContent: "center",
  },
  scrollable: {
    padding: Padding.p_2xs,
    maxWidth: "100%",
    width: "100%",
    flex: 1,
  },
  deviceDetail: {
    backgroundColor: Color.colorGhostwhite,
    alignItems: "center",
    width: "100%",
    flex: 1,
  },
});

export default PeriodTickets;
