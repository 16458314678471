import React, { useState, useCallback, useEffect } from 'react';
import { StyleSheet, View, Pressable, Text, ScrollView, ActivityIndicator, RefreshControl } from "react-native";
import { Image } from "expo-image";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase, useRoute, useFocusEffect } from "@react-navigation/native";
import { Color, Padding, Border, FontSize, FontFamily } from "../GlobalStyles";
import { useOrg } from '../lib/useOrg';
import { getCombinedTotalsByOrg, getLocationsByOrgId, getDevicesByOrgId } from "../lib/dash";
import TopBar from "../components/TopBar";
import { ChevronUp, ChevronDown } from "lucide-react";

const ViewModeTabs = ({ activeMode, onModeChange }) => (
  <View style={styles.tabContainer}>
    <Pressable
      style={[styles.tab, activeMode === 'all' && styles.activeTab]}
      onPress={() => onModeChange('all')}
    >
      <Text style={[styles.tabText, activeMode === 'all' && styles.activeTabText]}>
        All Devices
      </Text>
    </Pressable>
    <Pressable
      style={[styles.tab, activeMode === 'location' && styles.activeTab]}
      onPress={() => onModeChange('location')}
    >
      <Text style={[styles.tabText, activeMode === 'location' && styles.activeTabText]}>
        By Location
      </Text>
    </Pressable>
  </View>
);

const SortHeader = ({ field, currentSort, onSort, label }) => (
  <Pressable 
    style={styles.sortHeader}
    onPress={() => onSort(field)}
  >
    <Text style={styles.sortLabel}>{label}</Text>
    {currentSort.field === field && (
      currentSort.ascending ? 
        <Text>^</Text> :
        <Text>v</Text>
    )}
  </Pressable>
);

const Devices = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const { currentOrganization } = useOrg();
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState('');
  const [viewMode, setViewMode] = useState('all');
  const [sort, setSort] = useState({ field: 'total_billin_amount', ascending: false });

  const fetchData = async () => {
    if (!currentOrganization?.orgid) {
      setError("Waiting for organization ID...");
      return;
    }

    setLoading(true);
    setRefreshing(true);
    try {
      const [fetchedTotals, fetchedLocations, fetchedDevices] = await Promise.all([
        getCombinedTotalsByOrg(currentOrganization.orgid),
        getLocationsByOrgId(currentOrganization.orgid, 100),
        getDevicesByOrgId(currentOrganization.orgid, 100),
      ]);
      setDevices(fetchedDevices);
      setError('');
    } catch (e) {
      console.error(e);
      setError("Error fetching data");
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  useFocusEffect(
    useCallback(() => {
      fetchData();
    }, [currentOrganization?.orgid])
  );

  const handleSort = (field) => {
    setSort(prev => ({
      field,
      ascending: prev.field === field ? !prev.ascending : true
    }));
  };

  const sortDevices = (devicesToSort) => {
    return [...devicesToSort].sort((a, b) => {
      let compareA = a[sort.field];
      let compareB = b[sort.field];
      
      // Handle string comparisons
      if (typeof compareA === 'string') {
        compareA = compareA.toLowerCase();
        compareB = compareB.toLowerCase();
      }
      
      if (compareA < compareB) return sort.ascending ? -1 : 1;
      if (compareA > compareB) return sort.ascending ? 1 : -1;
      return 0;
    });
  };

  const groupByLocation = (devicesToGroup) => {
    const grouped = devicesToGroup.reduce((acc, device) => {
      const location = device.locationname || 'Unknown Location';
      if (!acc[location]) {
        acc[location] = [];
      }
      acc[location].push(device);
      return acc;
    }, {});

    // Sort devices within each location
    Object.keys(grouped).forEach(location => {
      grouped[location] = sortDevices(grouped[location]);
    });

    return grouped;
  };

  const renderDevice = (device) => (
    <Pressable
      key={device.deviceid}
      style={styles.locationsFlexBox}
      onPress={() => navigation.navigate("DeviceDetail", { deviceid: device.deviceid })}
    >
      <View style={[styles.locationcard, styles.dashCardBorder]}>
        <Image
          style={styles.locationimgIcon}
          contentFit="cover"
          source={require("../assets/deviceimg.png")}
        />
        <View style={[styles.devicecardcontent, styles.devicecardcontentLayout]}>
          <Text style={[styles.locationName, styles.locationNameLayout]} numberOfLines={1}>
            {device.devicename}
          </Text>
          <View style={styles.details3}>
            <View style={styles.locframe}>
              <Text style={styles.text4Typo}>{device.locationname}</Text>
            </View>
            <View style={styles.moneyinframe}>
              <Image
                style={styles.pluscircleIcon1}
                contentFit="cover"
                source={require("../assets/pluscircle2.png")}
              />
              <Text style={[styles.nodevicesval, styles.text4Typo]}>
                ${device.total_billin_amount}
              </Text>
            </View>
            <View style={styles.moneyinframe}>
              <Image
                style={styles.pluscircleIcon1}
                contentFit="cover"
                source={require("../assets/minuscircle2.png")}
              />
              <Text style={[styles.nodevicesval, styles.text4Typo]}>
                ${device.total_ticketout_amount}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Pressable>
  );

  if (loading && !refreshing) {
    return (
      <View style={styles.centered}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  const sortedDevices = sortDevices(devices);
  const groupedDevices = groupByLocation(devices);

  return (
    <View style={styles.deviceDetail}>
      <TopBar 
        title="All Devices"
        navigation={navigation}
        showBack={true}
      />

      <ViewModeTabs activeMode={viewMode} onModeChange={setViewMode} />

      <View style={styles.sortContainer}>
        <SortHeader 
          field="devicename"
          currentSort={sort}
          onSort={handleSort}
          label="Name"
        />
        <SortHeader 
          field="total_billin_amount"
          currentSort={sort}
          onSort={handleSort}
          label="Bill In"
        />
        <SortHeader 
          field="total_ticketout_amount"
          currentSort={sort}
          onSort={handleSort}
          label="Ticket Out"
        />
      </View>

      <ScrollView
        style={styles.scrollable}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={fetchData}
          />
        }
      >
        {error ? (
          <Text style={styles.errorText}>{error}</Text>
        ) : viewMode === 'all' ? (
          sortedDevices.map(renderDevice)
        ) : (
          Object.entries(groupedDevices).map(([location, locationDevices]) => (
            <View key={location} style={styles.locationGroup}>
              <Text style={styles.locationHeader}>{location}</Text>
              {locationDevices.map(renderDevice)}
            </View>
          ))
        )}
        <View style={{ height: 40 }} />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabContainer: {
    flexDirection: 'row',
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderBottomWidth: 1,
    borderBottomColor: Color.colorGainsboro_100,
  },
  tab: {
    flex: 1,
    paddingVertical: 16,
    alignItems: 'center',
  },
  activeTab: {
    borderBottomWidth: 3,
    borderBottomColor: Color.colorGold,
  },
  tabText: {
    fontSize: FontSize.size_base,
    color: Color.labelColorLightSecondary,
    fontFamily: FontFamily.interRegular,
  },
  activeTabText: {
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interSemiBold,
  },
  sortContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 12,
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderBottomWidth: 1,
    borderBottomColor: Color.colorGainsboro_100,
  },
  sortHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  sortLabel: {
    fontSize: FontSize.size_smi,
    color: Color.labelColorLightSecondary,
    marginRight: 4,
    fontFamily: FontFamily.interSemiBold,
  },
  locationGroup: {
    marginTop: 16,
  },
  locationHeader: {
    fontSize: FontSize.size_base,
    fontFamily: FontFamily.interSemiBold,
    color: Color.labelColorLightPrimary,
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  scrollableScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  moneyinframe: {
    marginLeft: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  locframe: {
    marginLeft: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  text4Typo: {
    fontSize: FontSize.size_smi,
    color: Color.labelColorLightSecondary,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    lineHeight: 18,
    textAlign: "left",
    letterSpacing: 0,
  },
  locationName: {
    fontSize: FontSize.size_mini,
    display: "flex",
    width: 262,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    alignItems: "center",
  },
  locationNameLayout: {
    lineHeight: 20,
    letterSpacing: 0,
  },
  devicecardcontentLayout: {
    width: 288,
    marginLeft: 10,
  },
  devicecardcontent: {
    height: 41,
    marginLeft: 10,
  },
  dashCardBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    flexDirection: "row",
  },
  locationsFlexBox: {
    marginTop: 11,
    alignSelf: "stretch",
    alignItems: "center",
  },
  topbarPosition: {
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  locationcardBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
  },
  textTypo: {
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontSize: FontSize.size_smi,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    letterSpacing: 0,
  },
  letterFlexBox: {
    textAlign: "center",
    color: Color.colorGray_100,
    zIndex: 1,
    lineHeight: 18,
    display: "flex",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
    justifyContent: "center",
    position: "absolute",
    alignItems: "center",
  },
  devicedetailscardSpaceBlock: {
    marginTop: 11,
    alignSelf: "stretch",
  },
  iconLayout: {
    height: 20,
    zIndex: 0,
    width: 20,
  },
  leticon2Position: {
    right: 14,
    top: 14,
    position: "absolute",
  },
  detailTypo: {
    fontFamily: FontFamily.interRegular,
    fontSize: FontSize.size_base,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    textAlign: "left",
    letterSpacing: 0,
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    height: 15,
    width: 20,
  },
  toptext: {
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  sliderhorizontal3Icon: {
    width: 21,
    height: 18,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    overflow: "hidden",
    alignItems: "center",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  locationimgIcon: {
    borderRadius: Border.br_12xs,
    width: 42,
    height: 42,
  },
  locationtitle: {
    fontSize: FontSize.size_mini,
    lineHeight: 20,
    width: 262,
    display: "flex",
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    letterSpacing: 0,
    alignItems: "center",
  },
  text: {
    display: "none",
    width: 92,
    alignItems: "center",
  },
  lanyardcardIcon: {
    width: 11,
    height: 15,
  },
  nodevicesval: {
    marginLeft: 1,
  },
  nodevicesframe: {
    marginLeft: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  pluscircleIcon: {
    height: 20,
    width: 20,
  },
  details2: {
    flexDirection: "row",
    alignItems: "center",
  },
  circleIcon: {
    zIndex: 0,
    width: 15,
    height: 15,
  },
  letter: {
    top: 2,
    left: 0,
    fontSize: FontSize.size_2xs,
    height: 12,
    zIndex: 1,
    width: 15,
  },
  leticon: {
    flexDirection: "row",
    alignItems: "center",
  },
  moneyoutframe1: {
    marginLeft: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  details3: {
    marginTop: 1,
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  details1: {
    width: 288,
  },
  content: {
    marginLeft: 10,
    width: 288,
  },
  locationcard: {
    backgroundColor: Color.systemBackgroundLightPrimary,
    padding: Padding.p_base,
    alignSelf: "stretch",
  },
  pluscircleIcon1: {
    width: 15,
    height: 15,
  },
  today1: {
    fontSize: FontSize.size_base,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    letterSpacing: 0,
  },
  text1: {
    marginTop: 2,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  total: {
    marginLeft: 15,
  },
  details4: {
    flexDirection: "row",
  },
  graph: {
    backgroundColor: Color.colorGainsboro_200,
    height: 70,
    marginTop: 10,
    alignSelf: "stretch",
  },
  content1: {
    zIndex: 1,
    marginLeft: 10,
    flex: 1,
  },
  dashWinsCard: {
    flexDirection: "row",
    flex: 1,
  },
  revenuecardswinsbets: {
    flexDirection: "row",
    alignItems: "center",
  },
  letter2: {
    top: 1,
    right: 0,
    fontSize: FontSize.size_sm,
    height: 17,
    zIndex: 1,
    width: 20,
  },
  leticon2: {
    zIndex: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  bodyText: {
    marginTop: 5,
  },
  detailitemgroup: {
    marginTop: 20,
    justifyContent: "center",
  },
  detail: {
    marginTop: 20,
  },
  devicedetailscard: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
    justifyContent: "center",
  },
  scrollable: {
    padding: Padding.p_2xs,
    maxWidth: "100%",
    width: "100%",
    flex: 1,
  },
  deviceDetail: {
    backgroundColor: Color.colorGhostwhite,
    alignItems: "center",
    width: "100%",
    flex: 1,
  },
});

export default Devices;
