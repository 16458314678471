import React, { useState } from 'react';
import { View, Text, Modal, Pressable, StyleSheet, Platform } from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { Color, Border, FontSize, FontFamily } from "../GlobalStyles";

const DateRangeFilter = ({ 
  startDate, 
  endDate, 
  onStartDateChange, 
  onEndDateChange,
  onClear 
}) => {
  const [showStartPicker, setShowStartPicker] = useState(false);
  const [showEndPicker, setShowEndPicker] = useState(false);

  const formatDate = (date) => {
    if (!date) return 'Select';
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const handleWebDateChange = (dateString, isStart) => {
    const date = new Date(dateString);
    if (isStart) {
      onStartDateChange(date);
    } else {
      onEndDateChange(date);
    }
  };

  const handleMobileDateChange = (event, selectedDate, isStart) => {
    const currentDate = selectedDate || (isStart ? startDate : endDate);
    
    if (Platform.OS === 'android') {
      setShowStartPicker(false);
      setShowEndPicker(false);
    }

    if (event.type === 'set') {
      if (isStart) {
        onStartDateChange(currentDate);
      } else {
        onEndDateChange(currentDate);
      }
    }

    if (Platform.OS === 'ios') {
      if (isStart) {
        setShowStartPicker(false);
      } else {
        setShowEndPicker(false);
      }
    }
  };

  const renderMobilePicker = (isStart) => {
    const show = isStart ? showStartPicker : showEndPicker;
    const currentDate = isStart ? startDate : endDate;
    const minDate = isStart ? undefined : startDate;
    const maxDate = isStart ? endDate : undefined;

    if (!show) return null;

    if (Platform.OS === 'ios') {
      return (
        <Modal
          visible={show}
          transparent={true}
          animationType="slide"
        >
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>
                Select {isStart ? 'Start' : 'End'} Date
              </Text>
              <DateTimePicker
                value={currentDate || new Date()}
                mode="date"
                display="spinner"
                onChange={(event, date) => handleMobileDateChange(event, date, isStart)}
                minimumDate={minDate}
                maximumDate={maxDate}
              />
              <Pressable
                style={styles.modalButton}
                onPress={() => isStart ? setShowStartPicker(false) : setShowEndPicker(false)}
              >
                <Text style={styles.modalButtonText}>Done</Text>
              </Pressable>
            </View>
          </View>
        </Modal>
      );
    }

    return (
      <DateTimePicker
        value={currentDate || new Date()}
        mode="date"
        display="default"
        onChange={(event, date) => handleMobileDateChange(event, date, isStart)}
        minimumDate={minDate}
        maximumDate={maxDate}
      />
    );
  };

  const DateInput = ({ isStart }) => {
    const currentDate = isStart ? startDate : endDate;
    const formattedDate = currentDate ? currentDate.toISOString().split('T')[0] : '';

    if (Platform.OS === 'web') {
      return (
        <input
          type="date"
          value={formattedDate}
          onChange={(e) => handleWebDateChange(e.target.value, isStart)}
          min={isStart ? undefined : startDate?.toISOString().split('T')[0]}
          max={isStart ? endDate?.toISOString().split('T')[0] : undefined}
          style={{
            border: 'none',
            background: 'transparent',
            fontSize: FontSize.size_smi,
            color: Color.labelColorLightPrimary,
            fontFamily: FontFamily.interRegular,
            cursor: 'pointer',
            width: '100px',
            padding: '4px'
          }}
        />
      );
    }

    return (
      <Pressable
        onPress={() => isStart ? setShowStartPicker(true) : setShowEndPicker(true)}
      >
        <Text style={styles.dateText}>{formatDate(currentDate)}</Text>
      </Pressable>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.dateContainer}>
        <View style={styles.dateButton}>
          <Text style={styles.dateLabel}>From</Text>
          <DateInput isStart={true} />
        </View>

        <Text style={styles.dateSeparator}>-</Text>

        <View style={styles.dateButton}>
          <Text style={styles.dateLabel}>To</Text>
          <DateInput isStart={false} />
        </View>

        {(startDate || endDate) && (
          <Pressable 
            style={styles.clearButton} 
            onPress={onClear}
            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
          >
            <Text style={styles.clearButtonText}>×</Text>
          </Pressable>
        )}
      </View>

      {Platform.OS !== 'web' && (
        <>
          {renderMobilePicker(true)}
          {renderMobilePicker(false)}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginRight: 8,
  },
  dateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
    borderWidth: 1,
    borderColor: Color.colorGainsboro_100,
    paddingVertical: 4,
  },
  dateButton: {
    paddingHorizontal: 12,
    paddingVertical: 4,
  },
  dateLabel: {
    fontSize: FontSize.size_xs,
    color: Color.labelColorLightSecondary,
    marginBottom: 2,
  },
  dateText: {
    fontSize: FontSize.size_smi,
    color: Color.labelColorLightPrimary,
  },
  dateSeparator: {
    color: Color.labelColorLightSecondary,
    paddingHorizontal: 4,
  },
  clearButton: {
    padding: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  clearButtonText: {
    fontSize: FontSize.size_base,
    color: Color.labelColorLightSecondary,
    fontWeight: '600',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 20,
  },
  modalTitle: {
    fontSize: FontSize.size_xl,
    fontWeight: '600',
    marginBottom: 16,
    textAlign: 'center',
    color: Color.labelColorLightPrimary,
  },
  modalButton: {
    marginTop: 16,
    padding: 16,
    backgroundColor: Color.colorGold,
    borderRadius: 8,
    alignItems: 'center',
  },
  modalButtonText: {
    fontSize: FontSize.size_base,
    fontWeight: '600',
    color: Color.labelColorLightPrimary,
  },
});

export default DateRangeFilter;