import * as React from "react";
import { useState, useEffect } from 'react';
import { StyleSheet, View, Text, ScrollView, Pressable, Modal, ActivityIndicator } from "react-native";
import { Image } from "expo-image";
import { useNavigation, useRoute } from "@react-navigation/native";
import { format } from 'date-fns';
import { supabase } from '../lib/supabase';
import { Color, Padding, Border, FontSize, FontFamily } from "../GlobalStyles";
import TopBar from "../components/TopBar";

const TicketPreview = ({ ticketHex, onPress }) => {
    return (
      <Pressable 
        onPress={onPress}
        style={styles.ticketWrapper}
      >
        <View style={styles.ticketContainer}>
          <ScrollView
            style={styles.scrollView}
            contentContainerStyle={styles.scrollContent}
            showsVerticalScrollIndicator={false}
            bounces={false}
          >
            <Text style={styles.ticketText}>
              {decodeHexToAscii(ticketHex)}
            </Text>
          </ScrollView>
        </View>
      </Pressable>
    );
};
  
const TicketModal = ({ visible, onClose, ticketHex }) => {
    return (
      <Modal
        visible={visible}
        animationType="slide"
        presentationStyle="pageSheet"
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalTicketContainer}>
            <ScrollView
              style={styles.modalScrollView}
              contentContainerStyle={styles.modalScrollContent}
              showsVerticalScrollIndicator={false}
            >
              <Text style={styles.modalTicketText}>
                {decodeHexToAscii(ticketHex)}
              </Text>
            </ScrollView>
          </View>
          <Pressable 
            style={styles.closeButton}
            onPress={onClose}
          >
            <Text style={styles.closeButtonText}>Close</Text>
          </Pressable>
        </View>
      </Modal>
    );
};

const decodeHexToAscii = (hex) => {
    try {
      const hexString = hex.toString();
      let str = '';
      for (let i = 0; i < hexString.length; i += 2) {
        const chunk = hexString.substr(i, 2);
        str += String.fromCharCode(parseInt(chunk, 16));
      }
      
      // Split into lines and clean up each line
      const lines = str
        .replace(/\x1b[@-_]/g, '')
        .replace(/[^\x20-\x7E\n]/g, '')
        .split('\n')
        .map(line => line.trim())
        .filter(line => line);
  
      // Group the lines according to the ticket format
      const formattedLines = [];
      
      // Header (location)
      formattedLines.push(lines[0]);
      formattedLines.push('');
      formattedLines.push('');
  
      // DateTime and Terminal
      const dateTimeAndTerminal = lines.filter(line => 
        line.includes('202') || line.includes('Terminal')
      );
      formattedLines.push(...dateTimeAndTerminal);
      formattedLines.push('');
  
      // Device name
      const deviceName = lines.find(line => line.includes('AURORA'));
      formattedLines.push(deviceName);
      formattedLines.push('');
  
      // Accounting section
      const accountingSection = lines.filter(line => 
        line.includes('Accounting') || 
        line.includes('From:') || 
        line.includes('To:')
      );
      formattedLines.push(...accountingSection);
      formattedLines.push('');
  
      // Money section
      const moneySection = lines.filter(line => 
        line.includes('Money In:') || 
        line.includes('Money Out:') || 
        line.includes('Total Net:')
      );
      formattedLines.push(...moneySection);
      formattedLines.push('');
  
      // Games section
      const gamesSection = lines.filter(line => 
        line.includes('Coin In:') || 
        line.includes('Coin Out:') || 
        line.includes('Games')
      );
      formattedLines.push(...gamesSection);
  
      return formattedLines.join('\n');
    } catch (e) {
      return 'Error decoding ticket data';
    }
};

const PeriodTicketDetail = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { eventid } = route.params;
  
  const [ticketData, setTicketData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showFullTicket, setShowFullTicket] = useState(false);

  const fetchTicketDetails = async () => {
    try {
      // Fetch ticket data
      const { data: ticket, error: ticketError } = await supabase
        .from('v2_analyticsaccountingtickets')
        .select('*')
        .eq('eventid', eventid)
        .single();

      if (ticketError) throw ticketError;

      // Fetch device info
      const { data: device, error: deviceError } = await supabase
        .from('devices')
        .select('*')
        .eq('deviceid', ticket.deviceid)
        .single();

      if (deviceError) throw deviceError;

      // Fetch location info
      const { data: location, error: locationError } = await supabase
        .from('locations')
        .select('*')
        .eq('locationid', device.locationid)
        .single();

      if (locationError) throw locationError;

      setTicketData({
        ticket,
        device,
        location
      });
    } catch (e) {
      console.error(e);
      setError("Error fetching ticket details");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTicketDetails();
  }, [eventid]);

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    return format(new Date(timestamp * 1000), 'EEEE, MMMM d at h:mm a');
  };

  const formatCurrency = (amount) => {
    if (!amount) return '$0.00';
    return `$${parseFloat(amount).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;
  };

  if (loading) {
    return (
      <View style={styles.centered}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  if (error) {
    return (
      <View style={styles.centered}>
        <Text>{error}</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <TopBar 
        title="Period Ticket" 
        navigation={navigation}
        showBack={true}
      />
      <ScrollView style={styles.scrollView}>
        {/* Physical Ticket Representation */}
        <TicketPreview 
        ticketHex={ticketData?.ticket?.ticket_hex}
        onPress={() => setShowFullTicket(true)}
        />

        {/* Main Info Section */}
        <View style={styles.infoSection}>
          <Text style={styles.dateText}>
            {formatDate(ticketData?.ticket?.function_time)}
          </Text>
          <Text style={styles.mainText}>{ticketData?.device?.devicename}</Text>
          <Text style={styles.subText}>{ticketData?.location?.locationname}</Text>
        </View>

        {/* Financial Data */}
        <View style={styles.dataCard}>
          <View style={styles.dataRow}>
            <Text style={styles.label}>Money In:</Text>
            <Text style={styles.value}>{formatCurrency(ticketData?.ticket?.total_in)}</Text>
          </View>
          <View style={styles.dataRow}>
            <Text style={styles.label}>Money Out:</Text>
            <Text style={styles.value}>{formatCurrency(ticketData?.ticket?.total_out)}</Text>
          </View>
          <View style={styles.dataRow}>
            <Text style={styles.label}>Net Gain:</Text>
            <Text style={styles.value}>{formatCurrency(ticketData?.ticket?.net_gain)}</Text>
          </View>
        </View>

        {/* Time Period */}
        <View style={styles.dataCard}>
          <View style={styles.dataRow}>
            <Text style={styles.label}>From:</Text>
            <Text style={styles.value}>
              {ticketData?.ticket?.from_date} {ticketData?.ticket?.from_time}
            </Text>
          </View>
          <View style={styles.dataRow}>
            <Text style={styles.label}>To:</Text>
            <Text style={styles.value}>
              {ticketData?.ticket?.to_date} {ticketData?.ticket?.to_time}
            </Text>
          </View>
        </View>

        {/* Game Stats */}
        <View style={styles.dataCard}>
          <View style={styles.dataRow}>
            <Text style={styles.label}>Coin In:</Text>
            <Text style={styles.value}>{formatCurrency(ticketData?.ticket?.coin_in)}</Text>
          </View>
          <View style={styles.dataRow}>
            <Text style={styles.label}>Coin Out:</Text>
            <Text style={styles.value}>{formatCurrency(ticketData?.ticket?.coin_out)}</Text>
          </View>
          <View style={styles.dataRow}>
            <Text style={styles.label}>Games Played:</Text>
            <Text style={styles.value}>{ticketData?.ticket?.games_played || 0}</Text>
          </View>
          <View style={styles.dataRow}>
            <Text style={styles.label}>Games Won:</Text>
            <Text style={styles.value}>{ticketData?.ticket?.games_won || 0}</Text>
          </View>
          <View style={styles.dataRow}>
            <Text style={styles.label}>Games Lost:</Text>
            <Text style={styles.value}>{ticketData?.ticket?.games_lost || 0}</Text>
          </View>
        </View>

        {/* Device Details Card */}
        <Pressable 
          style={styles.detailsCard}
          onPress={() => navigation.navigate("DeviceDetail", { deviceid: ticketData?.device?.deviceid })}
        >
          <Text style={styles.cardTitle}>{ticketData?.device?.devicename} details</Text>
          <Text style={styles.cardBody}>{ticketData?.device?.devicedesc}</Text>
        </Pressable>

        {/* Location Details Card */}
        <Pressable 
          style={styles.detailsCard}
          onPress={() => navigation.navigate("LocationDetail", { locationid: ticketData?.location?.locationid })}
        >
          <Text style={styles.cardTitle}>{ticketData?.location?.locationname}</Text>
          <View style={styles.detailGroup}>
            <Text style={styles.cardBody}>{ticketData?.location?.locationaddress}</Text>
            <Text style={styles.cardBody}>{ticketData?.location?.locationcity}</Text>
            <Text style={styles.cardBody}>{ticketData?.location?.locationstate}</Text>
            <Text style={styles.cardBody}>{ticketData?.location?.locationcountry}</Text>
          </View>
        </Pressable>
      </ScrollView>

      {/* Full Screen Ticket Modal */}
      <TicketModal
      visible={showFullTicket}
      onClose={() => setShowFullTicket(false)}
      ticketHex={ticketData?.ticket?.ticket_hex}
        />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.colorGhostwhite,
  },
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollView: {
    flex: 1,
    padding: 16,
  },
  ticketWrapper: {
    width: '50%',
    height: 300,
    alignSelf: 'center',
    marginBottom: 20,
  },

  ticketContainer: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#E0E0E0',
    elevation: 2,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
  },
  scrollContent: {
    padding: 20,
    paddingTop: 24,
  },

  ticketText: {
    fontFamily: 'Courier',
    fontSize: 13,
    lineHeight: 24,
    color: '#000000',
  },

  modalContainer: {
    flex: 1,
    backgroundColor: '#F5F5F5',
    padding: 16,
  },

  modalTicketContainer: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#000000',
    marginBottom: 16,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 4,
  },

  modalScrollView: {
    flex: 1,
  },

  modalScrollContent: {
    padding: 24,
    paddingTop: 32,
  },

  modalTicketText: {
    fontFamily: 'Courier',
    fontSize: 16,
    lineHeight: 28,
    color: '#000000',
  },

  closeButton: {
    backgroundColor: '#FFD700',
    borderRadius: 8,
    padding: 16,
    alignItems: 'center',
    marginBottom: 32,
  },

  closeButtonText: {
    color: '#000000',
    fontSize: 16,
    fontWeight: '600',
  },
  
  ticketScroll: {
    flex: 1,
  },

  ticketScrollContent: {
    padding: 16,
  },

  ticketContent: {
    minHeight: '100%',
  },
  modalScroll: {
    flex: 1,
  },
  infoSection: {
    marginBottom: 20,
  },
  dateText: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 8,
    color: Color.labelColorLightPrimary,
  },
  mainText: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 4,
    color: Color.labelColorLightPrimary,
  },
  subText: {
    fontSize: 16,
    color: Color.labelColorLightSecondary,
  },
  dataCard: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 16,
    marginBottom: 16,
    elevation: 2,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
  },
  dataRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#f0f0f0',
  },
  label: {
    fontSize: 16,
    color: Color.labelColorLightSecondary,
  },
  value: {
    fontSize: 16,
    fontWeight: '600',
    color: Color.labelColorLightPrimary,
  },
  detailsCard: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 16,
    marginBottom: 16,
    elevation: 2,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flex: 1,
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 8,
    color: Color.labelColorLightPrimary,
  },
  cardBody: {
    fontSize: 16,
    color: Color.labelColorLightSecondary,
    marginBottom: 4,
  },
  detailGroup: {
    marginTop: 8,
  },
  hamburgerIcon: {
    height: 15,
    width: 20,
  },
  organizationName: {
    fontWeight: "600",
    fontFamily: FontFamily.interSemiBold,
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },

});



export default PeriodTicketDetail;