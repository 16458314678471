import * as React from 'react';
import {
  StyleSheet, View, Pressable, Text, ScrollView, TextInput, RefreshControl,
} from 'react-native';
import { useState, useEffect } from 'react';
import { useNavigation, useRoute, ParamListBase } from '@react-navigation/native';

import { DrawerNavigationProp } from '@react-navigation/drawer';
import { Image } from 'expo-image';
import { Color, Padding, Border, FontFamily, FontSize } from '../GlobalStyles';
import { useOrg } from '../lib/useOrg';
import { getLocationsByOrgId } from '../lib/dash';

const AddSelectLocation = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const route = useRoute();
  const { currentOrganization } = useOrg();
  const [locations, setLocations] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    try {
      await fetchData();
    } catch (e) {
      console.error(e);
    }
    setRefreshing(false);
  }, [currentOrganization?.orgid]);

  useEffect(() => {
    if (route.params?.refresh) {
      onRefresh();
    }
  }, [route.params?.refresh, onRefresh]);

  const fetchData = async () => {
    if (!currentOrganization?.orgid) {
      setLoading(false);
      //setError('Organization ID is missing.');
      return;
    }

    setLoading(true);
    setLocations([]);
    try {
      const fetchedLocations = await getLocationsByOrgId(currentOrganization.orgid, 100);
      setLocations(fetchedLocations.flat());
    } catch (e) {
      console.error(e);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentOrganization?.orgid]);

  


  return (
    <View style={styles.addSelectlocation}>
      <View style={styles.top}>
        <View style={[styles.statusbar, styles.topbarPosition]} />
        <View style={[styles.topbar, styles.topbarPosition]}>
          <View style={styles.topbarinner}>
          <Pressable
            style={{
              width: 60, // Set a specific width for the button
              height: 60, // Set a specific height for the button
              justifyContent: 'center',
              alignItems: 'center',
              padding: 10, // Optional: additional padding
              backgroundColor: 'transparent', // Ensure background is transparent
            }}
            onPress={() => navigation.goBack()}
          >
            <Image
              style={{
                width: '60%', // Make the image fill the button
                height: '60%', // Make the image fill the button
                resizeMode: 'contain', // Ensure the image maintains its aspect ratio
              }}
              source={require("../assets/calender.png")}
            />
          </Pressable>
            <View style={styles.details}>
              <Text style={styles.toptext}>Add Device</Text>
            </View>
          </View>
        </View>
      </View>
      <ScrollView
        style={styles.frame}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.frameScrollViewContent}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={fetchData} />}
      >


      { /* FUTURE MAP

        <View style={[styles.locationmap, styles.locationmapBorder]}>
          <Text style={styles.toptext}>? Map of nearby locations</Text>
        </View>


        //  Add search at some point

        <TextInput
          style={[styles.searchfield, styles.searchfieldFlexBox]}
          placeholder="Search by Name"
          placeholderTextColor="rgba(60, 60, 67, 0.6)"
        />

          */  }

        <Pressable
          style={[styles.alllocationsbtn, styles.dashCardBorder, { marginBottom: 10 }]}
          onPress={() => navigation.navigate("Wifi1")}
        ><Text style={[styles.alllocationstxt, styles.locationNameLayout]}>Wifi Prov Test</Text></Pressable>


        {locations && locations.map((location) => (

        <Pressable
          style={[styles.locationcard, styles.searchfieldFlexBox]}
          onPress={() => navigation.navigate("AddConnectWifi", {locationid: location.locationid})}
          key={location.locationid}
        >
          <View style={styles.content}>
            <View style={styles.details1}>
              <Image
                style={styles.locationimgIcon}
                contentFit="cover"
                source={require("../assets/locationimg1.png")}
              />
              <View style={styles.details2}>
                <Text style={[styles.locationName, styles.locationNameTypo]}>
                {location.locationname}
                </Text>
                <View style={styles.address}>
                  <Text
                    style={[styles.yinzStBitsburgh, styles.locationNameTypo]}
                  >
                    {location.locationaddress} {location.locationcity}, {location.locationstate}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </Pressable>

        ))}


        <Pressable
          style={[styles.alllocationsbtn, styles.dashCardBorder, { marginBottom: 50 }]}
          onPress={() =>
            navigation.navigate("LocationEdit", { toptitle: "New Location", backtitle: "Select Location", nextpage: "AddSelectLocation" })
          }
        >
          <Text style={[styles.alllocationstxt, styles.locationNameLayout]}>
            Add New Location
          </Text>
        </Pressable>


      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  alllocationstxt: {
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.colorGray_300,
    textAlign: "center",
    fontSize: FontSize.size_xl,
    lineHeight: 20,
    height: 17,
  },
  locationNameLayout: {
    lineHeight: 20,
    letterSpacing: 0,
  },
  alllocationsbtn: {
    backgroundColor: Color.colorWhitesmoke,
    height: 37,
    marginTop: 11,
    alignSelf: "stretch",
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "center",
  },
  dashCardBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    flexDirection: "row",
  },
  frameScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  topbarPosition: {
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  locationmapBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
  },
  searchfieldFlexBox: {
    marginTop: 11,
    flexDirection: "row",
    alignSelf: "stretch",
  },
  locationNameTypo: {
    textAlign: "left",
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    letterSpacing: 0,
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    width: 20,
    height: 15,
  },
  toptext: {
    fontSize: FontSize.size_xl,
    lineHeight: 25,
    fontWeight: "600",
    fontFamily: FontFamily.interSemiBold,
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    letterSpacing: 0,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    overflow: "hidden",
    alignItems: "center",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  locationmap: {
    height: 250,
    justifyContent: "center",
    alignSelf: "stretch",
    alignItems: "center",
  },
  searchfield: {
    borderRadius: Border.br_3xs,
    backgroundColor: Color.fillColorLightTertiary,
    height: 36,
    paddingHorizontal: Padding.p_5xs,
    paddingVertical: Padding.p_6xs,
    fontFamily: FontFamily.interRegular,
    fontSize: FontSize.size_mid,
    alignItems: "center",
  },
  locationimgIcon: {
    borderRadius: Border.br_12xs,
    width: 30,
    height: 30,
  },
  locationName: {
    fontSize: FontSize.size_smi,
    lineHeight: 13,
    color: Color.labelColorLightPrimary,
  },
  yinzStBitsburgh: {
    fontSize: FontSize.size_xs,
    lineHeight: 15,
    color: Color.colorGray_300,
  },
  address: {
    marginTop: 2,
    flexDirection: "row",
    alignSelf: "stretch",
  },
  details2: {
    marginLeft: 8,
  },
  details1: {
    flexDirection: "row",
    alignItems: "center",
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  locationcard: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
    overflow: "hidden",
  },
  frame: {
    padding: Padding.p_2xs,
    maxWidth: "100%",
    width: "100%",
    flex: 1,
  },
  addSelectlocation: {
    backgroundColor: Color.colorGhostwhite,
    alignItems: "center",
    width: "100%",
    flex: 1,
  },
});

export default AddSelectLocation;
