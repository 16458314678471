import React, { useState } from 'react';
import { View, Text, ScrollView, Pressable, Modal, StyleSheet, Image } from 'react-native';
import { Color, Border, FontSize, FontFamily, Padding } from "../GlobalStyles";
import DateRangeFilter from './DateRangeFilter';

const FilterBar = ({
  locations = [],
  devices = [],
  selectedLocation,
  selectedDevice,
  sortField,
  sortAscending,
  onLocationChange,
  onDeviceChange,
  onSortChange,
  onClearFilters,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}) => {
  const [showLocationPicker, setShowLocationPicker] = useState(false);
  const [showDevicePicker, setShowDevicePicker] = useState(false);

  const FilterButton = ({ label, value, onPress, isActive }) => (
    <Pressable 
      style={[styles.filterButton, isActive && styles.filterButtonActive]}
      onPress={onPress}
    >
      <Text style={[styles.filterButtonText, isActive && styles.filterButtonTextActive]}>
        {value || label}
      </Text>
      <Image
        style={styles.filterIcon}
        contentFit="cover"
        source={require("../assets/chevrondown.png")}
      />
    </Pressable>
  );

  const SortButton = ({ label, field, isActive, isAscending, onPress }) => (
    <Pressable 
      style={[styles.filterButton, isActive && styles.filterButtonActive]}
      onPress={() => onPress(field)}
    >
      <Text style={[styles.filterButtonText, isActive && styles.filterButtonTextActive]}>
        {label}
      </Text>
      <Image
        style={[
          styles.sortIcon,
          isActive && !isAscending && styles.sortIconReverse
        ]}
        contentFit="cover"
        source={require("../assets/chevronup.png")}
      />
    </Pressable>
  );

  return (
    <>
      <ScrollView
        horizontal
        style={styles.filterBar}
        showsHorizontalScrollIndicator={false}
      >
        <View style={styles.filterContent}>
          <FilterButton
            label="Location"
            value={selectedLocation?.locationname}
            onPress={() => setShowLocationPicker(true)}
            isActive={!!selectedLocation}
          />
          
          <FilterButton
            label="Device"
            value={selectedDevice?.devicename}
            onPress={() => setShowDevicePicker(true)}
            isActive={!!selectedDevice}
          />

          <DateRangeFilter
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
            onClear={() => {
              onStartDateChange(null);
              onEndDateChange(null);
            }}
          />
          
          <SortButton
            label="Money In"
            field="total_in"
            isActive={sortField === 'total_in'}
            isAscending={sortAscending}
            onPress={onSortChange}
          />
          
          <SortButton
            label="Money Out"
            field="total_out"
            isActive={sortField === 'total_out'}
            isAscending={sortAscending}
            onPress={onSortChange}
          />
          
          <SortButton
            label="Net Gain"
            field="net_gain"
            isActive={sortField === 'net_gain'}
            isAscending={sortAscending}
            onPress={onSortChange}
          />

          {(selectedLocation || selectedDevice || sortField !== 'function_time') && (
            <Pressable 
              style={styles.clearButton}
              onPress={onClearFilters}
            >
              <Text style={styles.clearButtonText}>Clear Filters</Text>
            </Pressable>
          )}
        </View>
      </ScrollView>

      {/* Location Picker Modal */}
      <Modal
        visible={showLocationPicker}
        transparent={true}
        animationType="slide"
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Select Location</Text>
            <ScrollView>
              <Pressable
                style={styles.modalItem}
                onPress={() => {
                  onLocationChange(null);
                  onDeviceChange(null);
                  setShowLocationPicker(false);
                }}
              >
                <Text style={styles.modalItemText}>All Locations</Text>
              </Pressable>
              {locations.map(location => (
                <Pressable
                  key={location.locationid}
                  style={styles.modalItem}
                  onPress={() => {
                    onLocationChange(location);
                    onDeviceChange(null);
                    setShowLocationPicker(false);
                  }}
                >
                  <Text style={styles.modalItemText}>{location.locationname}</Text>
                </Pressable>
              ))}
            </ScrollView>
            <Pressable
              style={styles.modalCloseButton}
              onPress={() => setShowLocationPicker(false)}
            >
              <Text style={styles.modalCloseButtonText}>Cancel</Text>
            </Pressable>
          </View>
        </View>
      </Modal>

      {/* Device Picker Modal */}
      <Modal
        visible={showDevicePicker}
        transparent={true}
        animationType="slide"
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Select Device</Text>
            <ScrollView>
              <Pressable
                style={styles.modalItem}
                onPress={() => {
                  onDeviceChange(null);
                  setShowDevicePicker(false);
                }}
              >
                <Text style={styles.modalItemText}>All Devices</Text>
              </Pressable>
              {(selectedLocation ? 
                devices.filter(d => d.locationid === selectedLocation.locationid) : 
                devices
              ).map(device => (
                <Pressable
                  key={device.deviceid}
                  style={styles.modalItem}
                  onPress={() => {
                    onDeviceChange(device);
                    setShowDevicePicker(false);
                  }}
                >
                  <Text style={styles.modalItemText}>{device.devicename}</Text>
                </Pressable>
              ))}
            </ScrollView>
            <Pressable
              style={styles.modalCloseButton}
              onPress={() => setShowDevicePicker(false)}
            >
              <Text style={styles.modalCloseButtonText}>Cancel</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  filterBar: {
    maxHeight: 60,
    backgroundColor: '#f5f5f5',
    borderBottomWidth: 1,
    borderBottomColor: Color.colorGainsboro_100,
  },
  filterContent: {
    flexDirection: 'row',
    padding: 12,
    alignItems: 'center',
    gap: 8,
  },
  filterButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: Color.colorGainsboro_100,
    borderRadius: Border.br_smi,
    backgroundColor: Color.systemBackgroundLightPrimary,
  },
  filterButtonActive: {
    backgroundColor: Color.colorGold,
    borderColor: Color.colorGold,
  },
  filterButtonText: {
    fontSize: FontSize.size_smi,
    color: Color.labelColorLightSecondary,
    marginRight: 8,
  },
  filterButtonTextActive: {
    color: Color.labelColorLightPrimary,
  },
  filterIcon: {
    width: 12,
    height: 12,
  },
  sortIcon: {
    width: 12,
    height: 12,
  },
  sortIconReverse: {
    transform: [{ rotate: '180deg' }],
  },
  clearButton: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: Border.br_smi,
    backgroundColor: Color.colorGhostwhite,
  },
  clearButtonText: {
    fontSize: FontSize.size_smi,
    color: Color.labelColorLightSecondary,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'flex-end',
  },
  modalContent: {
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 20,
    maxHeight: '80%',
  },
  modalTitle: {
    fontSize: FontSize.size_xl,
    fontWeight: '600',
    marginBottom: 16,
    color: Color.labelColorLightPrimary,
  },
  modalItem: {
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: Color.colorGainsboro_100,
  },
  modalItemText: {
    fontSize: FontSize.size_base,
    color: Color.labelColorLightPrimary,
  },
  modalCloseButton: {
    marginTop: 16,
    padding: 16,
    backgroundColor: Color.colorGold,
    borderRadius: 8,
    alignItems: 'center',
  },
  modalCloseButtonText: {
    fontSize: FontSize.size_base,
    fontWeight: '600',
    color: Color.labelColorLightPrimary,
  },
  dateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
    borderWidth: 1,
    borderColor: Color.colorGainsboro_100,
    paddingVertical: 4,
    marginRight: 8,
  },
  
  dateButton: {
    paddingHorizontal: 12,
    paddingVertical: 4,
  },
  
  dateLabel: {
    fontSize: FontSize.size_xs,
    color: Color.labelColorLightSecondary,
    marginBottom: 2,
  },
  
  dateText: {
    fontSize: FontSize.size_smi,
    color: Color.labelColorLightPrimary,
  },
  
  dateSeparator: {
    color: Color.labelColorLightSecondary,
    paddingHorizontal: 4,
  },
});

export default FilterBar;