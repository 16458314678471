import React from 'react';
import { View, StyleSheet, Platform, Text, Pressable, Image } from 'react-native';
import { Color, FontFamily, FontSize } from "../GlobalStyles";

const TOPBAR_HEIGHT = Platform.OS === 'web' ? 50 : 94;
const STATUSBAR_HEIGHT = Platform.OS === 'ios' ? 44 : Platform.OS === 'android' ? 24 : 0;
const NAV_BUTTON_SIZE = 44;

const TopBar = ({ 
  title, 
  navigation, 
  showBack = false, // if true shows back, if false shows menu
  onBackPress,      // optional custom back handler
  onMenuPress       // optional custom menu handler
}) => {
  const handleBack = () => {
    if (onBackPress) {
      onBackPress();
    } else if (navigation?.goBack) {
      navigation.goBack();
    }
  };

  const handleMenu = () => {
    if (onMenuPress) {
      onMenuPress();
    } else if (navigation?.toggleDrawer) {
      navigation.toggleDrawer();
    }
  };

  const renderNavButton = () => {
    if (showBack) {
      return (
        <Pressable 
          style={styles.navButton}
          onPress={handleBack}
          hitSlop={8}
        >
          <Image
            style={styles.navIcon}
            contentFit="cover"
            source={require("../assets/calender.png")}
          />
        </Pressable>
      );
    }

    return (
      <Pressable 
        style={styles.navButton}
        onPress={handleMenu}
        hitSlop={8}
      >
        <Image
          style={styles.navIcon}
          contentFit="cover"
          source={require("../assets/hamburger.png")}
        />
      </Pressable>
    );
  };

  return (
    <View style={styles.container}>
      {Platform.OS !== 'web' && <View style={styles.statusbar} />}
      <View style={styles.topbar}>
        <View style={styles.content}>
          {renderNavButton()}
          <View style={styles.titleContainer}>
            <Text style={styles.title} numberOfLines={1}>
              {title}
            </Text>
          </View>
          {/* Empty View for spacing */}
          <View style={styles.navButton} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: TOPBAR_HEIGHT,
    width: '100%',
    backgroundColor: Color.colorGold,
  },

  statusbar: {
    height: STATUSBAR_HEIGHT,
    width: '100%',
    backgroundColor: Color.colorGold,
  },

  topbar: {
    flex: 1,
    justifyContent: 'center',
  },

  content: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 8,
    height: NAV_BUTTON_SIZE,
  },

  navButton: {
    width: NAV_BUTTON_SIZE,
    height: NAV_BUTTON_SIZE,
    justifyContent: 'center',
    alignItems: 'center',
  },

  navIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },

  titleContainer: {
    flex: 1,
    marginHorizontal: 8,
  },

  title: {
    fontFamily: FontFamily.interSemiBold,
    fontSize: FontSize.size_xl,
    fontWeight: "600",
    color: Color.labelColorLightPrimary,
    textAlign: 'left',
    lineHeight: 25,
  },
});

export default TopBar;

// Usage examples:
/*
// With back button:
<TopBar 
  title="Period Ticket"
  navigation={navigation}
  showBack={true}
/>

// With menu button:
<TopBar 
  title="Dashboard"
  navigation={navigation}
  showBack={false}
/>

// With custom handlers:
<TopBar 
  title="Custom Navigation"
  showBack={true}
  onBackPress={() => {
    // Custom back logic
  }}
/>
*/