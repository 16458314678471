import React, { useState, useEffect } from 'react';
import { View, ActivityIndicator } from 'react-native';
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { useFonts } from "expo-font";

// Import all your screens and components here
import Dash from "./screens/Dash";
import Devices from "./screens/Devices";
import Splash from "./screens/Splash";
import Login from "./screens/Login";
import AddConnectWifi from "./screens/AddConnectWifi";
import AddDeviceConfirmation from "./screens/AddDeviceConfirmation";
import AddSelectLocation from "./screens/AddSelectLocation";
import AddDeviceDetails from "./screens/AddDeviceDetails";
import AddWifDetails from "./screens/AddWifDetails";
import AddWifiConfirmation from "./screens/AddWifiConfirmation";
import DrawerMenu from "./components/DrawerMenu";
import Locations from "./screens/Locations";
import DeviceDetail from "./screens/DeviceDetail";
import DeviceEdit from "./screens/DeviceEdit";
import Log from "./screens/Log";
import LogDetail from "./screens/LogDetail";
import LocationDetail from "./screens/LocationDetail";
import LocationEdit from "./screens/LocationEdit";
import Revenue from "./screens/Revenue";
import RevenueDetail from "./screens/RevenueDetail";
import MyAccount from "./screens/MyAccount";
import AppSettings from "./screens/AppSettings";
import Scan from "./screens/Scan";
import Inventory from "./screens/Inventory";
import NewOrg from "./screens/NewOrg";
import AddQRSetup from "./screens/AddQRSetup";
import NewBoardSuccess from "./screens/NewBoardSuccess";
import ItemDetails from "./screens/ItemDetails";
import InventoryQR from "./screens/InventoryQR";
import NewBoardSetup from "./screens/NewBoardSetup";
import PeriodTickets from "./screens/PeriodTickets";
import PeriodTicketDetail from "./screens/PeriodTicketDetail";
import Wifi1 from './screens/Wifi1';

// Import navigation and authentication libraries
import SessionContext from './lib/SessionContext';
import { Session } from '@supabase/supabase-js';
import { supabase } from './lib/supabase';
import getOrganizationDetails from "./lib/org";
import { Wifi } from 'lucide-react';

// Initialize navigators
const Stack = createNativeStackNavigator();
const Drawer = createDrawerNavigator();

// Drawer Navigator for the main authenticated app
function DrawerRoot() {
  return (
    <Drawer.Navigator
      screenOptions={{ headerShown: false, drawerStyle: { width: 320 } }}
      initialRouteName="Dash"
      drawerContent={(props) => <DrawerMenu {...props} />}
    >
      <Drawer.Screen name="Dash" component={Dash} />
      <Drawer.Screen name="Locations" component={Locations} />
      <Drawer.Screen name="Devices" component={Devices} />
      <Drawer.Screen name="Revenue" component={Revenue} />
      <Drawer.Screen name="PeriodTickets" component={PeriodTickets} />
      <Drawer.Screen name="AddSelectLocation" component={AddSelectLocation} />
      <Drawer.Screen name="Scan" component={Scan} />
      <Drawer.Screen name="AppSettings" component={AppSettings} />
      <Drawer.Screen name="MyAccount" component={MyAccount} />
    </Drawer.Navigator>
  );
}


function BSNavigator() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="BS" component={NewBoardSetup} />
      {/* Add other BS-related screens here if needed */}
    </Stack.Navigator>
  );
}

function MainNavigator({ session, currentOrganization }) {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      {session && session.user ? (
        currentOrganization ? (
          <>
            <Stack.Screen name="DrawerRoot" component={DrawerRoot} />
            <Stack.Screen name="Splash" component={Splash} />
            <Stack.Screen name="AddConnectWifi" component={AddConnectWifi} />
            <Stack.Screen name="AddDeviceConfirmation" component={AddDeviceConfirmation} />
            <Stack.Screen name="AddDeviceDetails" component={AddDeviceDetails} />
            <Stack.Screen name="AddWifDetails" component={AddWifDetails} />
            <Stack.Screen name="AddWifiConfirmation" component={AddWifiConfirmation} />
            <Stack.Screen name="DeviceDetail" component={DeviceDetail} />
            <Stack.Screen name="AddQRSetup" component={AddQRSetup} />
            <Stack.Screen name="DeviceEdit" component={DeviceEdit} />
            <Stack.Screen name="Log" component={Log} />
            <Stack.Screen name="LogDetail" component={LogDetail} />
            <Stack.Screen name="LocationDetail" component={LocationDetail} />
            <Stack.Screen name="LocationEdit" component={LocationEdit} />
            <Stack.Screen name="RevenueDetail" component={RevenueDetail} />
            <Stack.Screen name="Inventory" component={Inventory} />
            <Stack.Screen name="InventoryQR" component={InventoryQR} />
            <Stack.Screen name="ItemDetails" component={ItemDetails} />
            <Stack.Screen name="NewBoardSuccess" component={NewBoardSuccess} />
            <Stack.Screen name="PeriodTicketDetail" component={PeriodTicketDetail} />
            {/*<Stack.Screen name="Wifi1" component={Wifi1} /> */}
          </>
        ) : (
          <Stack.Screen name="NewOrg" component={NewOrg} />
        )
      ) : (
        <Stack.Screen name="Login" component={Login} />
      )}
    </Stack.Navigator>
  );
}

const App = () => {
  const [session, setSession] = useState<Session | null>(null);
  const [currentOrganization, setOrganizationDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fontsLoaded, error] = useFonts({
    "Inter-Regular": require("./assets/fonts/Inter-Regular.ttf"),
    "Inter-Medium": require("./assets/fonts/Inter-Medium.ttf"),
    "Inter-SemiBold": require("./assets/fonts/Inter-SemiBold.ttf"),
    "Inter-Bold": require("./assets/fonts/Inter-Bold.ttf"),
    "Roboto-Medium": require("./assets/fonts/Roboto-Medium.ttf"),
    "Roboto-Bold": require("./assets/fonts/Roboto-Bold.ttf"),
  });

  // Deep linking
  const linking = {
    prefixes: ['https://sgkfyp.com', 'sgkfyp://', 'http://localhost:8081'],
    config: {
      screens: {
        BS: 'bs/:did?',
        DrawerRoot: {
          path: 'dash',
          screens: {
            Dash: '',
            Locations: 'locations',
            Devices: 'devices',
            Revenue: 'revenue',
            AddSelectLocation: 'add-location',
            Scan: 'scan',
            AppSettings: 'settings',
            MyAccount: 'account',
            PeriodTickets: 'period-tickets-all',
            NewBoardSuccess: 'nbs',
            PeriodTicketDetail: 'periodticket'
          },
        },
        Login: 'login',
        NewOrg: 'new-organization',
      },
    },
  };

  // Fetch session on mount
  useEffect(() => {
    const fetchSession = async () => {
      setIsLoading(true);
      const sessionResponse = await supabase.auth.getSession();
      setSession(sessionResponse.data.session);
      setIsLoading(false);

      const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session);
      });

      return () => {
        authListener.unsubscribe();
      };
    };

    fetchSession();
  }, []);

  // Fetch organization details when session changes
  useEffect(() => {
    const fetchAndUpdateOrganizationDetails = async () => {
      if (session?.user?.email) {
        const details = await getOrganizationDetails(session.user.email);
        if (details && details.length > 0) {
          setOrganizationDetails(details[0]);
        }
      }
    };

    fetchAndUpdateOrganizationDetails();
  }, [session?.user?.email]);

  // Handle font loading errors
  if (error) {
    console.error("Error loading fonts:", error);
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Text>Error loading fonts.</Text>
      </View>
    );
  }

  // Show loading indicator while fonts or session are loading
  if (!fontsLoaded || isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  // Determine if the current path is for BS
  const isBSPath =
    typeof window !== 'undefined' &&
    window.location &&
    window.location.pathname.startsWith('/bs');

  return (
    <SessionContext.Provider value={{ session }}>
      <NavigationContainer linking={linking}>
        {isBSPath ? (
          <BSNavigator />
        ) : (
          <MainNavigator session={session} currentOrganization={currentOrganization} />
        )}
      </NavigationContainer>
    </SessionContext.Provider>
  );
};

export default App;
