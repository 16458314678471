// 
//    Needs grouping fix
//

import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { StyleSheet, View, Pressable, Text, ScrollView, RefreshControl, ActivityIndicator } from 'react-native';
import { Image } from 'expo-image';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useNavigation, ParamListBase, useRoute, useFocusEffect } from '@react-navigation/native';
import { FontFamily, Padding, Color, Border, FontSize } from '../GlobalStyles';
import { supabase } from '../lib/supabase';
import { useOrg } from '../lib/useOrg';
import TopBar from "../components/TopBar";
import FilterBar from "../components/FilterBar";

interface Event {
  eventid: string;
  deviceid: string;
  type: string;
  value: number;
  bills: string;
  device_time: number;
  function_time: number;
  ticket_hex?: string;
  terminal_number?: string;
  header?: string;
  address?: string;
  ticket_time?: string;
}

interface GroupedEvent {
  amount: number;
  timestamp: number;
  deviceId: string;
  bills?: string;
  billsArray?: number[];
  type: string;
  eventIds: string[];
}

const groupEvents = (events: Event[]): GroupedEvent[] => {
  const sortedEvents = [...events].sort((a, b) => b.function_time - a.function_time);
  const groupedEvents: GroupedEvent[] = [];
  const processedEvents = new Set<string>();

  for (const event of sortedEvents) {
    if (processedEvents.has(event.eventid)) continue;

    // Find related event within 30 second window
    const relatedEvent = sortedEvents.find(e => 
      e.eventid !== event.eventid && 
      !processedEvents.has(e.eventid) &&
      e.deviceid === event.deviceid &&
      Math.abs(e.function_time - event.function_time) <= 30 && // Changed to 30 seconds
      ((event.type === 'meterin' && e.type === 'serialbill') ||
       (event.type === 'serialbill' && e.type === 'meterin'))
    );

    if (relatedEvent) {
      // Use serialbill event for bills info
      const billEvent = event.type === 'serialbill' ? event : relatedEvent;
      const meterEvent = event.type === 'meterin' ? event : relatedEvent;

      // Parse bills string - handle format like """10,5,1,1,10"""
      const billsStr = billEvent.bills?.replace(/["\s]/g, ''); // Remove quotes and whitespace
      const billsArray = billsStr?.split(',').map(Number) || [];

      groupedEvents.push({
        amount: Number(billEvent.value),
        timestamp: Math.max(billEvent.function_time, meterEvent.function_time),
        deviceId: billEvent.deviceid,
        bills: billsStr,
        billsArray: billsArray,
        type: billEvent.type === 'serialbill' ? 'Bill In' : 'Meter In',
        eventIds: [event.eventid, relatedEvent.eventid]
      });

      processedEvents.add(event.eventid);
      processedEvents.add(relatedEvent.eventid);
    } else {
      // Handle single events
      groupedEvents.push({
        amount: Number(event.value),
        timestamp: event.function_time,
        deviceId: event.deviceid,
        type: event.type === 'serialbill' ? 'Bill In' : 
              event.type === 'meterin' ? 'Meter In' :
              event.type === 'meterout' ? 'Meter Out' : 
              event.type === 'ticketout' ? 'Ticket Out' : event.type,
        eventIds: [event.eventid]
      });
      processedEvents.add(event.eventid);
    }
  }

  return groupedEvents;
};

const formatGroupedEvent = (event: GroupedEvent): string => {
  let display = `${event.type} $${event.amount}`;
  
  if (event.billsArray && event.billsArray.length > 1) {
    display += ` (${event.billsArray.length})`;
  }
  
  if (event.bills) {
    display += `\nBills: "${event.bills}"`;
  }
  
  return display;
};

export { groupEvents, formatGroupedEvent, type GroupedEvent, type Event };

const EVENT_TYPES = {
  'meterin': { label: 'Meter In', color: '#34A853' },
  'serialbill': { label: 'Bill In', color: '#34A853' },
  'meterout': { label: 'Meter Out', color: '#EA4335' },
  'serialtickethex': { label: 'Ticket Out', color: '#EA4335' },
};

const ViewModeTabs = ({ activeMode, onModeChange }) => (
  <View style={styles.tabContainer}>
    <Pressable
      style={[styles.tab, activeMode === 'all' && styles.activeTab]}
      onPress={() => onModeChange('all')}
    >
      <Text style={[styles.tabText, activeMode === 'all' && styles.activeTabText]}>
        All Events
      </Text>
    </Pressable>
    <Pressable
      style={[styles.tab, activeMode === 'grouped' && styles.activeTab]}
      onPress={() => onModeChange('grouped')}
    >
      <Text style={[styles.tabText, activeMode === 'grouped' && styles.activeTabText]}>
        Grouped Events
      </Text>
    </Pressable>
  </View>
);

const Log = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const route = useRoute();
  const { currentOrganization } = useOrg();

  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  
  // Filter states
  const [locations, setLocations] = useState([]);
  const [devices, setDevices] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [sortField, setSortField] = useState('function_time');
  const [sortAscending, setSortAscending] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [viewMode, setViewMode] = useState('all');
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);
  const [displayedEvents, setDisplayedEvents] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const handleParams = async () => {
      if (route.params?.deviceid) {
        const { data } = await supabase
          .from('devices')
          .select('*')
          .eq('deviceid', route.params.deviceid)
          .single();
        if (data) setSelectedDevice(data);
      } else if (route.params?.locationid) {
        const { data } = await supabase
          .from('locations')
          .select('*')
          .eq('locationid', route.params.locationid)
          .single();
        if (data) setSelectedLocation(data);
      }
    };
    
    handleParams();
  }, [route.params]);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortAscending(!sortAscending);
    } else {
      setSortField(field);
      setSortAscending(true);
    }
  };

  const clearFilters = () => {
    setSelectedLocation(null);
    setSelectedDevice(null);
    setSortField('function_time');
    setSortAscending(false);
    setStartDate(null);
    setEndDate(null);
  };

  const processEvents = (events) => {
    if (viewMode === 'all') {
      return events.map(event => ({
        ...event,
        displayType: EVENT_TYPES[event.type]?.label || event.type,
        color: EVENT_TYPES[event.type]?.color
      }));
    }

    // Group events by type, value, and time window (5 minutes)
    const groupedEvents = events.reduce((acc, event) => {
      const timeWindow = Math.floor(event.function_time / 300); // 5 minute windows
      const key = `${event.type}-${event.value}-${event.deviceid}-${timeWindow}`;
      
      if (!acc[key]) {
        acc[key] = {
          ...event,
          count: 1,
          bills: event.bills ? [event.bills] : [],
          displayType: EVENT_TYPES[event.type]?.label || event.type,
          color: EVENT_TYPES[event.type]?.color,
          groupedTime: event.function_time // Keep the most recent time
        };
      } else {
        acc[key].count += 1;
        if (event.bills) acc[key].bills.push(event.bills);
        // Update time if this event is more recent
        if (event.function_time > acc[key].groupedTime) {
          acc[key].groupedTime = event.function_time;
          acc[key].function_time = event.function_time;
        }
      }
      return acc;
    }, {});

    return Object.values(groupedEvents)
      .sort((a, b) => b.groupedTime - a.groupedTime);
  };

  const fetchData = async (loadMore = false) => {
    if (!currentOrganization?.orgid) {
      setError("Waiting for organization ID...");
      return;
    }

    const currentPage = loadMore ? page + 1 : 0;
    setLoading(!loadMore);
    setRefreshing(!loadMore);

    try {
      // Get devices
      const { data: deviceData, error: devicesError } = await supabase
        .from('devices')
        .select('deviceid, devicename, devicedesc, locationid')
        .eq('orgid', currentOrganization.orgid);

      if (devicesError) throw devicesError;
      setDevices(deviceData);

      // Build events query
      let eventsQuery = supabase
        .from('v2_analyticsevents')
        .select('*');

      if (selectedDevice) {
        eventsQuery = eventsQuery.eq('deviceid', selectedDevice.deviceid);
      } else if (selectedLocation) {
        const locationDevices = deviceData
          .filter(d => d.locationid === selectedLocation.locationid)
          .map(d => d.deviceid);
        eventsQuery = eventsQuery.in('deviceid', locationDevices);
      }

      if (startDate) {
        eventsQuery = eventsQuery.gte('function_time', Math.floor(startDate.getTime() / 1000));
      }
      if (endDate) {
        const nextDay = new Date(endDate);
        nextDay.setDate(nextDay.getDate() + 1);
        eventsQuery = eventsQuery.lt('function_time', Math.floor(nextDay.getTime() / 1000));
      }

      // Apply filters
      if (selectedEventTypes.length > 0) {
        eventsQuery = eventsQuery.in('type', selectedEventTypes);
      }

      // Pagination
      const limit = 200;
      eventsQuery = eventsQuery
        .order('function_time', { ascending: false })
        .range(currentPage * limit, (currentPage + 1) * limit - 1);

      const { data: eventData, error: eventError } = await eventsQuery;
      if (eventError) throw eventError;

      // Get locations
      const locationIds = [...new Set(deviceData
        .map(device => device.locationid)
        .filter(id => id))];

      const { data: locationData, error: locationError } = await supabase
        .from('locations')
        .select('locationid, locationname')
        .in('locationid', locationIds);

      if (locationError) throw locationError;
      setLocations(locationData);

      // Create device and location maps
      const deviceMap = deviceData.reduce((acc, device) => {
        acc[device.deviceid] = device;
        return acc;
      }, {});

      const locationMap = locationData.reduce((acc, loc) => {
        acc[loc.locationid] = loc.locationname;
        return acc;
      }, {});

      // Enrich events with device and location info
      const enrichedEvents = eventData.map(event => {
        const deviceInfo = deviceMap[event.deviceid] || {};
        return {
          ...event,
          deviceName: deviceInfo.devicename || 'Unknown Device',
          deviceDesc: deviceInfo.devicedesc || '',
          locationName: locationMap[deviceInfo.locationid] || 'Unknown Location',
          color: event.type.toLowerCase().includes('in') ? Color.labelColorLightPrimary : Color.labelColorLightSecondary
        };
      });

      const processedEvents = processEvents(enrichedEvents);
      setDisplayedEvents(loadMore ? [...displayedEvents, ...processedEvents] : processedEvents);
      setHasMore(eventData.length === limit);
      setPage(currentPage);

      setLogs(enrichedEvents);
      setError(null);
    } catch (e) {
      console.error(e);
      setError("Error fetching data");
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  useFocusEffect(
    useCallback(() => {
      fetchData();
    }, [currentOrganization?.orgid, selectedLocation, selectedDevice, sortField, sortAscending, startDate, endDate])
  );

  const renderEvent = (event) => (
    <View style={styles.logcardBorder} key={event.eventid}>
      <View style={styles.content}>
        <View style={styles.eventInfo}>
          <View style={styles.eventandtime}>
            <Text style={[styles.logEvent, { color: event.color }]}>
              {event.displayType} {event.value ? `$${event.value}` : ''}
              {event.count > 1 && ` (${event.count})`}
            </Text>
            <Text style={styles.timestamp}>
              {new Date(event.function_time * 1000).toLocaleString()}
            </Text>
          </View>
          {viewMode === 'grouped' && event.count > 1 && event.bills && Array.isArray(event.bills) && event.bills.length > 0 && (
            <Text style={styles.billsText}>
              Bills: {[...new Set(event.bills.filter(Boolean))].join(', ')}
            </Text>
          )}
          <Text style={styles.deviceName}>{event.deviceName}</Text>
          <Text style={styles.locationName}>{event.locationName}</Text>
        </View>
      </View>
    </View>
  );

  if (loading && !refreshing) {
    return (
      <View style={styles.centered}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  return (
    <View style={styles.log}>
      <TopBar 
        title={selectedDevice?.devicename || selectedLocation?.locationname || "Event Log"}
        navigation={navigation}
        showBack={true}
      />

      <ViewModeTabs activeMode={viewMode} onModeChange={setViewMode} />

      <FilterBar
        locations={locations}
        devices={devices}
        selectedLocation={selectedLocation}
        selectedDevice={selectedDevice}
        sortField={sortField}
        sortAscending={sortAscending}
        onLocationChange={setSelectedLocation}
        onDeviceChange={setSelectedDevice}
        onSortChange={handleSort}
        onClearFilters={clearFilters}
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        eventTypes={Object.entries(EVENT_TYPES).map(([key, { label }]) => ({
          value: key,
          label
        }))}
        selectedEventTypes={selectedEventTypes}
        onEventTypeChange={setSelectedEventTypes}
      />

      <ScrollView
        style={styles.scrollable}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={() => fetchData()} />
        }
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      >
        {error ? (
          <Text style={styles.errorText}>{error}</Text>
        ) : (
          <>
            {displayedEvents.map(renderEvent)}
            {hasMore && (
              <Pressable 
                style={styles.loadMoreButton}
                onPress={() => fetchData(true)}
              >
                <Text style={styles.loadMoreText}>Load More</Text>
              </Pressable>
            )}
          </>
        )}
        <View style={{ height: 40 }} />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  scrollableScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  topbarinnerFlexBox: {
    justifyContent: "space-between",
    height: 35,
    alignItems: "center",
    alignSelf: "stretch",
  },
  detailsSpaceBlock: {
    paddingVertical: 0,
    flexDirection: "row",
  },
  titleTypo: {
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
  },
  logEventTypo: {
    textAlign: "center",
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    letterSpacing: 0,
  },
  logcardBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    backgroundColor: Color.systemBackgroundLightPrimary,
    flexDirection: "row",
    overflow: "hidden",
    alignSelf: "stretch",
    marginBottom: 5,
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    width: 20,
    height: 15,
  },
  toptext: {
    fontSize: FontSize.size_xl,
    lineHeight: 25,
    color: Color.labelColorLightPrimary,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    flex: 1,
  },
  sliderhorizontal3Icon: {
    width: 21,
    height: 18,
  },
  topbarinner: {
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  sliderhorizontal3Icon1: {
    width: 14,
    height: 12,
  },
  title: {
    fontSize: FontSize.size_mini,
    lineHeight: 20,
    color: Color.colorDimgray_100,
    marginLeft: 2,
  },
  sort: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  filter: {
    marginLeft: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  filterinner: {
    paddingHorizontal: Padding.p_8xs,
    justifyContent: "space-between",
    height: 35,
    alignItems: "center",
    alignSelf: "stretch",
  },
  filtermenu: {
    height: 30,
    backgroundColor: Color.systemBackgroundLightPrimary,
    overflow: "hidden",
    justifyContent: "center",
    alignSelf: "stretch",
  },
  logEvent: {
    lineHeight: 13,
    fontSize: FontSize.size_smi,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    color: Color.labelColorLightPrimary,
  },
  timestamp: {
    color: Color.colorDimgray_200,
    marginLeft: 10,
    lineHeight: 13,
    fontSize: FontSize.size_smi,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
  },
  eventandtime: {
    flexDirection: "row",
    justifyContent: "center",
  },
  details2: {
    fontSize: FontSize.size_xs,
    lineHeight: 15,
    color: Color.colorGray_300,
  },
  detailsall: {
    width: 260,
    marginTop: 2,
    flexDirection: "row",
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  logcard1: {
    marginTop: 11,
  },
  scrollable: {
    padding: Padding.p_2xs,
    alignSelf: "stretch",
    flex: 1,
  },
  log: {
    backgroundColor: Color.colorGhostwhite,
    width: "100%",
    flex: 1,
  },
  tabContainer: {
    flexDirection: 'row',
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderBottomWidth: 1,
    borderBottomColor: Color.colorGainsboro_100,
  },
  tab: {
    flex: 1,
    paddingVertical: 16,
    alignItems: 'center',
  },
  activeTab: {
    borderBottomWidth: 3,
    borderBottomColor: Color.colorGold,
  },
  tabText: {
    fontSize: FontSize.size_base,
    color: Color.labelColorLightSecondary,
    fontFamily: FontFamily.interRegular,
  },
  activeTabText: {
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interSemiBold,
  },
  billsText: {
    fontSize: FontSize.size_xs,
    color: Color.labelColorLightSecondary,
    fontStyle: 'italic',
    marginTop: 2,
  },
  loadMoreButton: {
    backgroundColor: Color.colorGold,
    padding: 12,
    borderRadius: 8,
    margin: 16,
    alignItems: 'center',
  },
  loadMoreText: {
    color: Color.labelColorLightPrimary,
    fontSize: FontSize.size_smi,
    fontWeight: '600',
  },
});

export default Log;