import * as React from "react";
import {
  StyleSheet,
  View,
  Pressable,
  Text,
  ScrollView,
  TextInput,
  Alert,
  Platform,
} from "react-native";
import { useState, useEffect } from "react";
import { Image } from "expo-image";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase, useRoute, RouteProp, NavigationProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Color, Padding, Border, FontFamily, FontSize } from "../GlobalStyles";
import { supabase } from '../lib/supabase';
import { getLocationBasic } from "../lib/loc";
import { useOrg } from "../lib/useOrg";
import SessionContext from "../lib/SessionContext";
import { Picker } from '@react-native-picker/picker';
import { Modal } from 'react-native';

type RootStackParamList = {
  NewBoardSetup: undefined;
  NewBoardSuccess: { deviceId: string };
  // Add other screens as needed
};

type NewBoardSetupRouteProp = RouteProp<RootStackParamList, 'BS'>;

interface Location {
  locationid: string;
  locationname: string;
}

const BoardStatusCard = ({ deviceId, onStatusChange }: { deviceId: string, onStatusChange: (status: 'online' | 'offline' | 'loading') => void }) => {
  const [status, setStatus] = useState<'online' | 'offline' | 'loading'>('loading');
  const [lastSeen, setLastSeen] = useState<string | null>(null);

  const checkBoardStatus = async () => {
    try {
      // First, check the last online time in the devices table
      const { data: deviceData, error: deviceError } = await supabase
        .from('devices')
        .select('lastonline')
        .eq('deviceid', deviceId)
        .single();

      if (deviceError) {
        console.error('Error fetching device last online time:', deviceError);
        setStatus('offline');
        onStatusChange('offline');
        return;
      }

      if (deviceData && deviceData.lastonline) {
        const lastOnlineTime = new Date(deviceData.lastonline);
        const now = new Date();
        const diffMinutes = (now.getTime() - lastOnlineTime.getTime()) / (1000 * 60);

        setLastSeen(lastOnlineTime.toLocaleString());
        setStatus(diffMinutes <= 45 ? 'online' : 'offline');
        onStatusChange(diffMinutes <= 45 ? 'online' : 'offline');
        return; // Exit if we found the last online time
      }

      // If no last online time, check analytics uptime
      const { data, error } = await supabase
        .from('v2_analyticsuptime')
        .select('device_time, function_time')
        .eq('deviceid', deviceId)
        .order('function_time', { ascending: false })
        .limit(1)
        .single();

      if (error) {
        console.error('Error fetching board status:', error);
        setStatus('offline');
        onStatusChange('offline');
        return;
      }

      if (data) {
        const lastEventTime = new Date(data.function_time * 1000); // Convert to milliseconds
        const now = new Date();
        const diffMinutes = (now.getTime() - lastEventTime.getTime()) / (1000 * 60);

        setLastSeen(lastEventTime.toLocaleString());
        setStatus(diffMinutes <= 45 ? 'online' : 'offline');
        onStatusChange(diffMinutes <= 45 ? 'online' : 'offline');
      } else {
        setStatus('offline');
        onStatusChange('offline');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus('offline');
      onStatusChange('offline');
    }
  };

  useEffect(() => {
    checkBoardStatus();
    const interval = setInterval(checkBoardStatus, 60000);
    return () => clearInterval(interval);
  }, [deviceId]);

  return (
    <View style={styles.boardStatusCard}>
      <Text style={styles.boardStatusTitle}>Board Status</Text>
      <Text style={styles.deviceIdText}>Device ID: {deviceId}</Text>
      <View style={[styles.statusIndicator, 
        status === 'online' ? styles.statusOnline : 
        status === 'offline' ? styles.statusOffline : 
        styles.statusLoading
      ]}>
        <Text style={styles.statusText}>
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </Text>
      </View>
      {lastSeen && (
        <Text style={styles.lastSeenText}>Last seen: {lastSeen}</Text>
      )}
    </View>
  );
};

const DeviceInfoCard = ({ deviceId, selectedLocationId, orgId }: { deviceId: string, selectedLocationId: string, orgId: string | null }) => {
  const [deviceName, setDeviceName] = useState('');
  const [deviceDesc, setDeviceDesc] = useState('');
  const [loading, setLoading] = useState(false);
  const { currentOrganization } = useOrg();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  const handleAddDevice = async () => {
    if (!deviceName.trim()) {
      Alert.alert('Error', 'Please enter a device name');
      return;
    }

    setLoading(true);
    console.log('Starting to add device...'); // Debugging log
    try {
      // Check if the device already exists
      const { data: existingDevice, error: existingDeviceError } = await supabase
        .from('devices')
        .select('orgid, devicename, devicedesc, locationid')
        .eq('deviceid', deviceId)
        .single();

      if (existingDeviceError) {
        console.error('Error fetching existing device:', existingDeviceError); // Debugging log
        throw existingDeviceError;
      }

      if (existingDevice) {
        // If the device exists and orgid is null, allow updates
        if (!existingDevice.orgid) {
          const { error: updateError } = await supabase
            .from('devices')
            .update({
              devicename: deviceName.trim(),
              devicedesc: deviceDesc.trim(),
              locationid: selectedLocationId,
              orgid: currentOrganization?.orgid,
            })
            .eq('deviceid', deviceId);

          if (updateError) {
            console.error('Error updating device:', updateError); // Debugging log
            throw updateError;
          }

          console.log('Attempting to navigate to NewBoardSuccess with deviceId:', deviceId);
          navigation.navigate('NewBoardSuccess', { deviceId: deviceId });
          return;
        } else {
          Alert.alert('Error', 'Device is already registered to an organization');
          return;
        }
      }

      // If the device does not exist, insert it
      const { error: deviceError } = await supabase
        .from('devices')
        .insert([{
          deviceid: deviceId,
          orgid: currentOrganization?.orgid,
          locationid: selectedLocationId,
          devicename: deviceName.trim(),
          devicedesc: deviceDesc.trim(),
        }]);

      if (deviceError) {
        console.error('Error adding device:', deviceError); // Debugging log
        throw deviceError;
      }

      console.log('Device added successfully, navigating to NewBoardSuccess'); // Debugging log
      navigation.navigate('NewBoardSuccess', { deviceId: deviceId });
      
      // Reset form fields
      setDeviceName('');
      setDeviceDesc('');
    } catch (e: any) {
      console.error('Error in handleAddDevice:', e); // Debugging log
      Alert.alert('Error', e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>Device Information</Text>
      <TextInput
        style={styles.input}
        onChangeText={setDeviceName}
        value={deviceName}
        placeholder="Device Name"
        autoCapitalize="none"
      />
      <TextInput
        style={[styles.input, styles.textArea]}
        onChangeText={setDeviceDesc}
        value={deviceDesc}
        placeholder="Device Description"
        multiline
        numberOfLines={3}
      />
      <Pressable
        style={styles.addButton}
        onPress={handleAddDevice}
        disabled={loading}
      >
        <Text style={styles.buttonText}>
          {loading ? 'Adding...' : 'Add Device'}
        </Text>
      </Pressable>
    </View>
  );
};

const LocationsList = ({ orgId, onLocationSelect }: { orgId: string, onLocationSelect: (locationId: string) => void }) => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [newLocationName, setNewLocationName] = useState('');

  // Fetch locations
  const fetchLocations = async () => {
    try {
      const { data, error } = await supabase
        .from('locations')
        .select('locationid, locationname')
        .eq('orgid', orgId);

      if (error) throw error;
      setLocations(data || []);
      if (data && data.length > 0 && !selectedLocation) {
        setSelectedLocation(data[0].locationid);
      }
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orgId) {
      fetchLocations();
    }
  }, [orgId]);

  useEffect(() => {
    if (selectedLocation) {
      onLocationSelect(selectedLocation);
    }
  }, [selectedLocation]);

  // Add new location
  const handleAddLocation = async () => {
    if (!newLocationName.trim()) {
      Alert.alert('Error', 'Please enter a location name');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('locations')
        .insert([
          {
            locationname: newLocationName.trim(),
            orgid: orgId,
          },
        ])
        .select()
        .single();

      if (error) throw error;

      // Refresh locations list and select the new location
      await fetchLocations();
      if (data) {
        setSelectedLocation(data.locationid);
        onLocationSelect(data.locationid);
      }
      setModalVisible(false);
      setNewLocationName('');
    } catch (e: any) {
      Alert.alert('Error', e.message);
    }
  };

  if (loading) return <Text>Loading locations...</Text>;
  if (error) return <Text>Error loading locations: {error}</Text>;

  return (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>Select Location</Text>
      <View style={styles.dropdownContainer}>
        <Picker
          selectedValue={selectedLocation}
          onValueChange={(itemValue) => setSelectedLocation(itemValue)}
          style={styles.picker}
        >
          {locations.map(location => (
            <Picker.Item 
              key={location.locationid} 
              label={location.locationname} 
              value={location.locationid} 
            />
          ))}
        </Picker>
      </View>

      <Pressable
        style={styles.addLocationButton}
        onPress={() => setModalVisible(true)}
      >
        <Text style={styles.addLocationButtonText}>Add New Location</Text>
      </Pressable>

      {/* Add Location Modal */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Add New Location</Text>
            <TextInput
              style={styles.input}
              onChangeText={setNewLocationName}
              value={newLocationName}
              placeholder="Enter location name"
              autoCapitalize="none"
            />
            <View style={styles.modalButtons}>
              <Pressable
                style={[styles.modalButton, styles.cancelButton]}
                onPress={() => setModalVisible(false)}
              >
                <Text style={styles.modalButtonText}>Cancel</Text>
              </Pressable>
              <Pressable
                style={[styles.modalButton, styles.addButton]}
                onPress={handleAddLocation}
              >
                <Text style={styles.modalButtonText}>Add</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const HeaderSection = ({ session }: { session: any }) => {
  const navigation = useNavigation();
  
  if (!session?.user) return null;
  
  return (
    <View style={styles.headerContainer}>
      <Pressable
        style={styles.backButton}
        onPress={() => navigation.navigate("DrawerRoot")}
      >
        <Text>Back to Dashboard</Text>
      </Pressable>
      
      <View style={styles.userInfoCard}>
        <Text style={styles.userEmail}>User: {session.user.email}</Text>
      </View>
    </View>
  );
};

const NewBoardSetup = () => {
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const route = useRoute<RouteProp<RootStackParamList, 'BS'>>();
  const { session } = React.useContext(SessionContext);
  const { currentOrganization } = useOrg();
  
  const deviceid = route.params?.did || 'No device ID';

  // Add new state for login form
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  // Add login function
  async function signInWithEmail() {
    setLoading(true);
    const { error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    if (error) {
      Platform.OS === 'web' ? alert(error.message) : Alert.alert(error.message);
    }
    setLoading(false);
  }

  useEffect(() => {
    // Check if running in a web environment
    if (Platform.OS === 'web') {
      if (!window.location.href.includes('/bs')) {
        if (session?.user) {
          navigation.navigate('DrawerRoot');
        } else {
          navigation.navigate('Login');
        }
      }
    } else {
      // Handle navigation for non-web environments (e.g., React Native)
      if (session?.user) {
        navigation.navigate('DrawerRoot');
      } else {
        navigation.navigate('Login');
      }
    }
  }, [session, navigation]);

  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);
  const [registeredOrgId, setRegisteredOrgId] = useState<string | null>(null);

  useEffect(() => {
    const checkDeviceRegistration = async () => {
      const { data, error } = await supabase
        .from('devices')
        .select('orgid')
        .eq('deviceid', deviceid)
        .single();
        
      if (data) {
        setIsRegistered(true);
        setRegisteredOrgId(data.orgid);
      }
    };
    
    checkDeviceRegistration();
  }, [deviceid]);

  const [boardStatus, setBoardStatus] = useState<'online' | 'offline' | 'loading'>('loading');

  return (
    <ScrollView style={styles.scrollContainer}>
      <View style={styles.container}>
        <HeaderSection session={session} />
        
        <View style={styles.contentContainer}>
          <BoardStatusCard deviceId={deviceid} onStatusChange={setBoardStatus} />
          
          {boardStatus === 'online' && (
            <View style={styles.innerContainer}>
              {session?.user ? (
                <>
                  {isRegistered ? (
                    <>
                      {registeredOrgId ? (
                        <>
                          <View style={styles.card}>
                            <Text style={styles.warningText}>Board can be set up</Text>
                          </View>
                          <View style={styles.card}>
                            <Text style={styles.cardTitle}>Hard Reset</Text>
                            <View style={styles.imageContainer}>
                              {/* Add placeholder for reset image */}
                            </View>
                            <Pressable style={styles.resetButton}>
                              <Text style={styles.buttonText}>Press this button to hard reset</Text>
                            </Pressable>
                          </View>
                        </>
                      ) : (
                        <>
                          {currentOrganization && (
                            <LocationsList 
                              orgId={currentOrganization.orgid} 
                              onLocationSelect={setSelectedLocationId}
                            />
                          )}
                          <DeviceInfoCard 
                            deviceId={deviceid} 
                            selectedLocationId={selectedLocationId}
                            orgId={currentOrganization?.orgid}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <Text style={styles.warningText}>Device is not registered. Please register the device first.</Text>
                  )}
                </>
              ) : (
                <View style={styles.loginContainer}>
                  <Text style={styles.loginTitle}>Please Log In</Text>
                  <TextInput
                    style={styles.input}
                    onChangeText={setEmail}
                    value={email}
                    placeholder="Email"
                    keyboardType="email-address"
                    autoCapitalize="none"
                  />
                  <TextInput
                    style={styles.input}
                    onChangeText={setPassword}
                    value={password}
                    placeholder="Password"
                    secureTextEntry
                    autoCapitalize="none"
                  />
                  <Pressable
                    style={styles.loginButton}
                    onPress={signInWithEmail}
                    disabled={loading}
                  >
                    <Text style={styles.loginButtonText}>
                      {loading ? 'Loading...' : 'Login'}
                    </Text>
                  </Pressable>
                </View>
              )}
            </View>
          )}
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
    backgroundColor: '#f5f5f5',
  },
  container: {
    flex: 1,
    padding: 20,
  },
  contentContainer: {
    alignItems: 'center',
    width: '100%',
  },
  innerContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  backButton: {
    padding: 10,
    backgroundColor: Color.colorGold,
    borderRadius: 5,
    marginBottom: 20,
  },
  deviceText: {
    fontSize: 18,
    color: Color.labelColorLightPrimary,
  },
  loginContainer: {
    width: '100%',
    maxWidth: 400,
    padding: 20,
  },
  loginTitle: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center',
  },
  input: {
    borderWidth: 1,
    borderColor: Color.colorGainsboro,
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
  },
  loginButton: {
    backgroundColor: Color.colorGold,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
  },
  loginButtonText: {
    color: Color.labelColorLightPrimary,
    fontSize: 16,
  },
  userContainer: {
    alignItems: 'center',
    gap: 10,
  },
  userEmail: {
    fontSize: 16,
    color: Color.labelColorLightPrimary,
  },
  userId: {
    fontSize: 14,
    color: Color.labelColorLightSecondary,
  },
  boardStatusCard: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginBottom: 20,
    width: '100%',
    maxWidth: 400,
  },
  boardStatusTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  deviceIdText: {
    fontSize: 16,
    marginBottom: 10,
  },
  statusIndicator: {
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    marginBottom: 10,
  },
  statusOnline: {
    backgroundColor: '#4CAF50',
  },
  statusOffline: {
    backgroundColor: '#f44336',
  },
  statusLoading: {
    backgroundColor: '#FFA500',
  },
  statusText: {
    color: 'white',
    fontWeight: 'bold',
  },
  lastSeenText: {
    fontSize: 14,
    color: Color.labelColorLightSecondary,
  },
  dropdownContainer: {
    borderWidth: 1,
    borderColor: Color.colorGainsboro,
    borderRadius: 5,
    marginBottom: 10,
    backgroundColor: 'white',
  },
  picker: {
    height: 50,
  },
  addLocationButton: {
    backgroundColor: '#e0e0e0',  // Light grey color
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    marginTop: 10,
  },
  addLocationButtonText: {
    color: Color.labelColorLightPrimary,
    fontSize: 16,
    fontWeight: '500',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    width: '80%',
    maxWidth: 400,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 15,
    textAlign: 'center',
  },
  modalButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalButton: {
    flex: 1,
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    marginHorizontal: 5,
  },
  cancelButton: {
    backgroundColor: '#ccc',
  },
  addButton: {
    backgroundColor: Color.colorGold,  // Yellow color
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    marginTop: 10,
    width: '100%',
  },
  modalButtonText: {
    color: Color.labelColorLightPrimary,
    fontSize: 16,
    fontWeight: '500',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    marginVertical: 10,
    width: '100%',
    maxWidth: 600,
    alignSelf: 'center',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
  },
  textArea: {
    height: 100,
    textAlignVertical: 'top',
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: '500',
  },
  headerContainer: {
    width: '100%',
    alignItems: 'center',
    marginBottom: 20,
  },
  userInfoCard: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    width: '100%',
    maxWidth: 400,
  },
  warningText: {
    color: 'red',
    textAlign: 'center',
  },
});

export default NewBoardSetup;